import {Toast as BootstrapToast} from "react-bootstrap";


export default function Toast({show = false, onClose, text = ""}) {

  return (
    <BootstrapToast onClose={onClose} show={show} delay={3000}
                    bg={"light"} autohide className="toast-container">
      <BootstrapToast.Header>
        <strong className="me-auto">알림</strong>
      </BootstrapToast.Header>
      <BootstrapToast.Body>{text}</BootstrapToast.Body>
    </BootstrapToast>
  )
}