import {format} from "date-fns";

export function dateFormat(date, withTime = false, formatStr) {
  if (!date) return "";
  let customFormat = formatStr ?? "yyyy.MM.dd";
  if (withTime && !formatStr) customFormat = "yyyy.MM.dd  HH:mm";
  return format(date, customFormat);
}

export function formatPhoneNumber(str) {
  let res = str;
  if (str?.includes("-")) res = str.replace("-", "");
  if (str?.includes(" ")) res = str.replace(" ", "");
  return res;
}