import React from 'react';
import {AuthContextProvider} from "context/AuthContext";
import {AlertContextProvider} from "context/AlertContext";
import {Route, Routes} from 'react-router';
import LoginPage from "pages/login";
import Layout from "./Layout";

function App() {
  return (
    <AuthContextProvider>
      <AlertContextProvider>
          <Routes>
            <Route path={"/"} element={<Layout selected={1}/>}/>
            <Route path={"/game"} element={<Layout selected={1}/>}/>
            <Route path={"/pool"} element={<Layout selected={2}/>}/>
            <Route path={"/event"} element={<Layout selected={3}/>}/>
            <Route path={"/statistic"} element={<Layout selected={4}/>}/>
            <Route path={"/settings"} element={<Layout selected={5}/>}/>
            <Route path={"/login"} element={<LoginPage />}/>
          </Routes>
      </AlertContextProvider>
    </AuthContextProvider>
  );
}

export default App;
