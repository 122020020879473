import {useContext, useState} from "react";
import {AuthContext} from "context/AuthContext";

export default function LoginPage() {

  const {signIn, errorMsg} = useContext(AuthContext);

  const [username, setUsername] = useState(localStorage.getItem("lastUserId") ?? "");
  const [password, setPassword] = useState("");

  function onEnter(e) {
    if (e.key === "Enter") {
      signIn(username, password)
    }
  }

  return (
    <div className="login-page">
      <h1>야옹상회 관리자</h1>
      <input type="text" placeholder="관리자 아이디" value={username}
             onChange={(e) => setUsername(e.target.value)}/>
      <input type="password" placeholder="비밀번호" value={password}
             onKeyDown={onEnter}
             onChange={(e) => setPassword(e.target.value)}/>
      {errorMsg && <p className="error-msg">{errorMsg}</p>}
      <button type="button" className="blue-btn"
              onClick={() => signIn(username, password)}>로그인</button>
    </div>
  )
}