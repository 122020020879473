import {useState} from "react";
import {Form} from "react-bootstrap";
import CustomDatePicker from "common/components/DatePicker";
import AdminApi from "common/api";
import {useContext} from "react";
import {format} from "date-fns";
import {AlertContext} from "context/AlertContext";
import ModalDialog from "common/components/Modal";
import React from "react";
import ImageInput from "common/components/ImageInput";

export default function EventCreatePage({onClose, count}) {

  const api = new AdminApi();
  const {setShow, setToastMsg} = useContext(AlertContext);

  const [confirmSave, setConfirmSave] = useState(false);

  const [orderNo, setOrderNo] = useState(1);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [checkType, setCheckType] = useState("POPUP");
  const [checkPosition, setCheckPosition] = useState("playground"); // 위치
  const [checkOption, setCheckOption] = useState("CURRENT"); // 옵션 (현재창/새창)
  const [checkUse, setCheckUse] = useState(true);

  const [bannerImage, setBannerImage] = useState(null);

  const positions = [
    {id: "playground", label: "놀이터"},
    {id: "pool", label: "수영장"},
    {id: "yaongcon", label: "야옹콘"},
    {id: "profile", label: "프로필"},
  ]

  function onSave() {
    if ([orderNo, title, startDate, endDate, bannerImage]
      .filter((el) => el === null || el === "" || el === undefined || (typeof el === "number" && isNaN(el))).length > 0) {
      alert("필수 항목 모두 입력해주세요");
      return;
    }
    let params = {
      orderNo: orderNo,
      title: title,
      type: "POPUP",
      link: link,
      location: checkPosition,
      exposureStart: format(startDate, "yyyy-MM-dd 00:00:00"),
      exposureEnd: format(endDate, "yyyy-MM-dd 23:59:59"),
      option: checkOption,
      useYn: checkUse ? "Y" : "N"
    }
    api.createEvent(params, bannerImage).then((res) => {
      if (res?.data?.result === "ok") {
        setToastMsg("저장되었습니다");
        onClose();
      } else {
        setToastMsg("실패하였습니다");
      }
      setShow(true);
    }).finally(() => {
      setConfirmSave(false);
    })
  }

  return (
    <div>
      <div className="form-container event-form">
        <div>
          <span>* 타입</span>
        </div>
        <div className="type-input">
          <Form.Check
            type={"checkbox"}
            id={`POPUP`}
            label={`이벤트 팝업`}
            checked={checkType==="POPUP"}
            onChange={(e) => setCheckType(e.target.id)}
          />
        </div>
        <div>
          <span>* 노출 위치</span>
        </div>
        <div className="type-input">
          {positions.map((el) => (
            <Form.Check
              type={"checkbox"}
              key={el.id}
              id={el.id}
              label={el.label}
              checked={checkPosition === el.id}
              onChange={() => setCheckPosition(el.id)}
            />
          ))}
        </div>
        <div>
          <span>* 제목</span>
        </div>
        <div>
          <input type="text" className="text-input" value={title}
                 onChange={(e) => setTitle(e.target.value)}/>
        </div>
        <div>
          <span>* 기간</span>
        </div>
        <div>
          <CustomDatePicker value={startDate} onChange={setStartDate} maxDate={endDate??undefined}/>
          <span style={{margin: '0 6px'}}>~</span>
          <CustomDatePicker value={endDate} onChange={setEndDate} minDate={startDate??undefined}/>
        </div>
        <div>
          <span>링크</span>
        </div>
        <div>
          <input type="text" className="text-input" value={link}
                 onChange={(e) => setLink(e.target.value)}/>
        </div>
        <div>
          <span>* 노출 순서</span>
        </div>
        <div>
          <input type="number" className="text-input" value={orderNo ? orderNo.toString() : ""}
                 max={count[checkPosition]+1} min={1}
                 onChange={(e) => setOrderNo(parseInt(e.target.value))}/>
        </div>
        <div>
          <span>* 옵션</span>
        </div>
        <div className="type-input">
          <Form.Check
            type={"checkbox"}
            id={`CURRENT`}
            label={`현재창`}
            checked={checkOption === "CURRENT"}
            onChange={() => setCheckOption("CURRENT")}
          />
          <Form.Check
            type={"checkbox"}
            id={`NEW`}
            label={`새창`}
            checked={checkOption === "NEW"}
            onChange={() => setCheckOption("NEW")}
          />
        </div>
        <div>
          <span>* 배너 이미지</span>
        </div>
        <div>
          <ImageInput id={"event-img"} onChange={setBannerImage}/>
        </div>
        <div>
          <span>* 사용여부</span>
        </div>
        <div className="type-input">
          <Form.Check
            type={"checkbox"}
            id={`useYes`}
            label={`사용`}
            checked={checkUse}
            onChange={() => setCheckUse(true)}
          />
          <Form.Check
            type={"checkbox"}
            id={`useNo`}
            label={`미사용`}
            checked={!checkUse}
            onChange={() => setCheckUse(false)}
          />
        </div>
      </div>

      <div className="flex">
        <button className="grey-btn ml-auto" onClick={onClose}>취소</button>
        <button className="blue-btn" style={{marginLeft: 8}} type="button" onClick={() => setConfirmSave(true)}>등록</button>
      </div>

      <ModalDialog show={confirmSave}
                   title={"확인"}
                   onHide={() => {
                     setConfirmSave(false)
                   }}
                   btnText={"예"} secondBtnText={"아니요"}
                   hasTwoButtons
                   onOk={onSave}
                   onClickSecond={() => {
                     setConfirmSave(false)
                   }}
      >
        <p style={{textAlign: 'center'}}>
          이벤트를 등록하시겠습니까?
        </p>
      </ModalDialog>
    </div>
  )
}