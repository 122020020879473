import Modal from 'react-bootstrap/Modal';

export default function ModalDialog(props) {

  const {
    title, children,
    hasTwoButtons = false,
    btnText = "Ok", onOk = () => props.onHide(),
    secondBtnText, onClickSecond = () => {},
    ...otherProps} = props;

  return (
    <Modal
      {...otherProps}
      size="md"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        {title &&
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        }
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <button className="blue-btn" onClick={onOk}>{btnText}</button>
        {hasTwoButtons &&
          <button className="grey-btn" onClick={onClickSecond}>{secondBtnText}</button>}
      </Modal.Footer>
    </Modal>
  );
}