import {useEffect, useState, useContext} from "react";
import Tabs from "common/components/Tabs";
import {AuthContext} from "context/AuthContext";
import React from "react";
import AdminApi from "common/api";
import {format} from "date-fns";
import {AlertContext} from "context/AlertContext";
import MembersTab from './tabs/members'

export default function StatisticTab() {
  const {isMobile} = useContext(AuthContext);

  const api = new AdminApi();
  const {setShow, setToastMsg} = useContext(AlertContext);
  
  const [poolMenuShow, setPoolMenuShow] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmShowOpen, setConfirmShowOpen] = useState(false);

  const [stats, setStats] = useState(null);
  const [priceInfo, setPriceInfo] = useState(null);
  const [poolInfo, setPoolInfo] = useState(null);

  const today = new Date();
  const [poolExposureEnd, setPoolExposureEnd] = useState(today); // 수영장이 서비스 메뉴에서 미노출 될 날짜
  
  function fetchPoolData() {
    api.getPoolInfo()
      .then((res) => {
        if (res?.infos) {
          setPoolInfo(res.infos);
          setPriceInfo(res.infos.price);
          if (res.infos.exposureEndDate !== null) {
            setPoolExposureEnd(res.infos.exposureEndDate);
            setPoolMenuShow(false);
          }
        } else setPriceInfo(null);
      })
  }

  useEffect(() => {
    api.getBookingStats()
      .then((res) => {
        if (res?.infos) {
          setStats(res.infos)
        } else setStats(null);
      })
    fetchPoolData();
  }, [])
  
  function updatePoolExposure(date, showBool) {
    api.updatePoolInfo({exposureEndDate: date ? format(date, "yyyy-MM-dd") : null})
      .then((res) => {
        if (res?.result === "ok") {
          setToastMsg("수정되었습니다");
          setPoolMenuShow(showBool);
          fetchPoolData();
        } else {
          setPoolExposureEnd(poolInfo.exposureEndDate ?? today);
          setToastMsg("실패하였습니다");
        }
        setShow(true);
      })
      .finally(() => {
        setConfirmOpen(false);
        setConfirmShowOpen(false);
      })
  }

  return (
    <div>
      <h1>통계</h1>
      <Tabs
        tabNames={["회원", "게임", "포인트"]}
        tabPages={[
          <MembersTab isMobile={isMobile}/>,
          <MembersTab isMobile={isMobile}/>,
          <MembersTab isMobile={isMobile}/>
        ]}
      />

      {/* <ModalDialog show={confirmShowOpen}
                   onHide={() => {
                     setConfirmShowOpen(false)
                   }}
                   btnText={"예"} secondBtnText={"아니요"}
                   hasTwoButtons
                   onOk={() => updatePoolExposure(null, true)}
                   onClickSecond={() => {
                     setConfirmShowOpen(false)
                   }}
      >
        <p style={{textAlign: 'center'}}>
          수영장 메뉴 미노출 설정을 초기화 하시겠습니까?
        </p>
      </ModalDialog> */}
    </div>
  )
}

