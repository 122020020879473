import {useState} from "react";
import {Form} from "react-bootstrap";
import CustomDatePicker from "common/components/DatePicker";
import {format} from "date-fns";
import AdminApi from "common/api";
import {useContext} from "react";
import {AlertContext} from "context/AlertContext";
import ModalDialog from "common/components/Modal";
import React from "react";
import ImageInput from "common/components/ImageInput";

export default function EventEditPage({data, onClose, count}) {

  const api = new AdminApi();
  const {setShow, setToastMsg} = useContext(AlertContext);
  const [startDate, setStartDate] = useState(new Date(data.exposureStart));
  const [endDate, setEndDate] = useState(new Date(data.exposureEnd));

  const [checkType, setCheckType] = useState(data.type);
  const [checkPosition, setCheckPosition] = useState(data.location); // 위치
  const [checkOption, setCheckOption] = useState(data.option); // 옵션 (현재창/새창)
  const [checkUse, setCheckUse] = useState(data.useYn === "Y");
  const [title, setTitle] = useState(data.title);
  const [link, setLink] = useState(data.link ?? "");
  const [orderNo, setOrderNo] = useState(data.orderNo);
  const [newBannerImage, setNewBannerImage] = useState(null);

  const [confirmSave, setConfirmSave] = useState(false);

  const positions = [
    {id: "playground", label: "놀이터"},
    {id: "pool", label: "수영장"},
    {id: "yaongcon", label: "야옹콘"},
    {id: "profile", label: "프로필"},
  ]

  function onSave() {
    if ([orderNo, title, startDate, endDate]
      .filter((el) => el === null || el === "" || el === undefined || (typeof el === "number" && isNaN(el))).length > 0) {
      alert("필수 항목 모두 입력해주세요");
      return;
    }
    let params = {
      eventId: data.eventId,
      currentOrderNo: data.orderNo,
      newOrderNo: orderNo,
      title: title,
      type: "POPUP",
      link: link,
      location: checkPosition,
      exposureStart: format(startDate, "yyyy-MM-dd 00:00:00"),
      exposureEnd: format(endDate, "yyyy-MM-dd 23:59:59"),
      option: checkOption,
      useYn: checkUse ? "Y" : "N",
      currentBannerImage: data.bannerImage,
    }
    api.updateEventInfo(params, newBannerImage)
      .then((res) => {
        if (res?.result === "ok") {
          setToastMsg("저장되었습니다");
          onClose();
        } else {
          setToastMsg("실패하였습니다");
        }
        setShow(true);
      })
      .finally(() => {
        setConfirmSave(false);
      })
  }

  return (
    <div>
      <div className="form-container event-form">
        <div>
          <span>* 타입</span>
        </div>
        <div className="type-input">
          <Form.Check
            type={"checkbox"}
            id={`POPUP`}
            label={`이벤트 팝업`}
            checked={checkType === "POPUP"}
            onChange={(e) => setCheckType(e.target.id)}
          />
        </div>
        <div>
          <span>* 노출 위치</span>
        </div>
        <div className="type-input">
          {positions.map((el) => (
            <Form.Check
              type={"checkbox"}
              key={el.id}
              id={el.id}
              label={el.label}
              checked={checkPosition === el.id}
              onChange={() => setCheckPosition(el.id)}
            />
          ))}
        </div>
        <div>
          <span>* 제목</span>
        </div>
        <div>
          <input type="text" className="text-input" value={title}
                 onChange={(e) => setTitle(e.target.value)}/>
        </div>
        <div>
          <span>* 기간</span>
        </div>
        <div>
          <CustomDatePicker value={startDate} onChange={setStartDate}/>
          <span style={{margin: '0 6px'}}>~</span>
          <CustomDatePicker value={endDate} onChange={setEndDate}/>
        </div>
        <div>
          <span>링크</span>
        </div>
        <div>
          <input type="text" className="text-input" value={link}
                 onChange={(e) => setLink(e.target.value)}/>
        </div>
        <div>
          <span>* 노출 순서</span>
        </div>
        <div>
          <input type="text" className="text-input" value={orderNo ? orderNo.toString() : ""}
                 min={1} max={count[checkPosition]+1}
                 onChange={(e) => setOrderNo(parseInt(e.target.value))}/>
        </div>
        <div>
          <span>* 옵션</span>
        </div>
        <div className="type-input">
          <Form.Check
            type={"checkbox"}
            id={`CURRENT`}
            label={`현재창`}
            checked={checkOption === "CURRENT"}
            onChange={() => setCheckOption("CURRENT")}
          />
          <Form.Check
            type={"checkbox"}
            id={`NEW`}
            label={`새창`}
            checked={checkOption === "NEW"}
            onChange={() => setCheckOption("NEW")}
          />
        </div>
        <div>
          <span>* 배너 이미지</span>
        </div>
        <div>
          <ImageInput defaultImage={data.bannerImage} id={"event-img"} onChange={setNewBannerImage}/>
        </div>
        <div>
          <span>* 사용여부 순서</span>
        </div>
        <div className="type-input">
          <Form.Check
            type={"checkbox"}
            id={`useYes`}
            label={`사용`}
            checked={checkUse}
            onChange={() => setCheckUse(true)}
          />
          <Form.Check
            type={"checkbox"}
            id={`useNo`}
            label={`미사용`}
            checked={!checkUse}
            onChange={() => setCheckUse(false)}
          />
        </div>
      </div>

      <div className="flex">
        <button type="button" className="grey-btn ml-auto" onClick={onClose}>취소</button>
        <button type="button" className="blue-btn" style={{marginLeft: 8}} onClick={() => setConfirmSave(true)}>저장</button>
      </div>

      <ModalDialog show={confirmSave}
                   title={"확인"}
                   onHide={() => {
                     setConfirmSave(false)
                   }}
                   btnText={"예"} secondBtnText={"아니요"}
                   hasTwoButtons
                   onOk={onSave}
                   onClickSecond={() => {
                     setConfirmSave(false)
                   }}
      >
        <p style={{textAlign: 'center'}}>
          저장하시겠습니까?
        </p>
      </ModalDialog>
    </div>
  )
}