/* eslint-disable */
import React, {createContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import AdminApi from "common/api";
import {differenceInMinutes} from "date-fns";


export const AuthContext = createContext({});

export const AuthContextProvider = ({children}) => {

  const api = new AdminApi();
  const token = localStorage.getItem("ACCESS_TOKEN");
  const authority = localStorage.getItem("USER") ? JSON.parse(localStorage.getItem("USER"))?.authority : null;

  const navigate = useNavigate();
  const [auth, setAuth] = useState(!!token);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => setIsMobile(window.innerWidth <= 768);

  const signIn = (id, pw) => {
    if (errorMsg) setErrorMsg(null);
    if (!id || !pw || id.trim() === "" || pw.trim() === "") {
      setErrorMsg("아이디 및 비밀번호를 입력해주세요");
      return;
    }

    api.signIn(id, pw).then((data) => {
      if (data?.result === "ok") {
        localStorage.setItem("ACCESS_TOKEN", data.token.accessToken);
        const expiresIn = (new Date()).setMinutes((new Date()).getMinutes() + Math.floor(data.token.accessTokenExpiresIn / 60000));
        localStorage.setItem("TOKEN", JSON.stringify({...data.token, expiresIn}));
        localStorage.setItem("USER", JSON.stringify(data.user));
        localStorage.setItem("lastUserId", id);
        setAuth(true);
        if (data.user.authority === "POOL_ADMIN") {
          navigate("/pool")
        } else navigate("/");
      } else 
      if (data?.result === "fail") {
        setErrorMsg("아이디 또는 비밀번호를 다시 확인해주세요");
      } else if (!data) {
        setErrorMsg("오류 발생했습니다");
      }
    })
  };

  const signOut = () => {
    setAuth(false);
    localStorage.removeItem("ACCESS_TOKEN");
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("USER");
    navigate("/login");
  };

  useEffect(() => {
    if (!auth && location.pathname !== "/login") {
      navigate("/login", {replace: true});
    } else if (auth && authority !== "MASTER") {
      if (authority === "POOL_ADMIN" && location.pathname !== "/pool") {
        alert("권한이 없습니다");
        navigate("/pool")
      }
      if (authority === "YAONG_ADMIN" && location.pathname === "/pool") {
        alert("권한이 없습니다");
        navigate("/")
      }
    }
  }, [location.pathname])


  function validateToken() {
    const now = new Date();
    const tokenInfo = JSON.parse(localStorage.getItem("TOKEN"));
    const userInfo = JSON.parse(localStorage.getItem("USER"));

    if (tokenInfo && userInfo && (differenceInMinutes(new Date(tokenInfo.expiresIn), now) < 10)) {
      api.refreshToken(userInfo.userNo, tokenInfo).then((data) => {
        if (data?.result === "ok") {
          localStorage.setItem("ACCESS_TOKEN", data.token.accessToken);
          const expiresIn = (new Date()).setMinutes((new Date()).getMinutes() + Math.floor(data.token.accessTokenExpiresIn / 60000));
          localStorage.setItem("TOKEN", JSON.stringify({...data.token, expiresIn}));
          return data.token.accessToken;
        } else {
          signOut();
        }
      })
    } else if (auth && (!tokenInfo || (tokenInfo && differenceInMinutes(new Date(tokenInfo.expiresIn), now) < 0))) {
      setAuth(false);
      navigate("/login", {replace: true});
    }
  }

  useEffect(() => {
    validateToken();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])


  return (
    <AuthContext.Provider
      value={{
        auth,
        errorMsg,
        isMobile,
        signIn,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};