import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/locale/ko';
import {ReactComponent as CalendarIcon} from "../../assets/icons/calendar.svg";


export default function CustomDatePicker(props) {

  registerLocale('ko', ko);

  const {
    placeholder = "",
    value,
    onChange = () => {},
    minDate,
    maxDate,
    showTime = false,
    disabled,
    excludeDates = []
  } = props;

  return (
    <div className="datepicker-wrapper">
      <DatePicker
        selected={value}
        onChange={(date) => onChange(date)}
        locale="ko"
        placeholderText={placeholder}
        minDate={minDate}
        maxDate={maxDate}
        showTimeSelect={showTime}
        timeFormat="p"
        timeIntervals={1}
        showIcon
        toggleCalendarOnIconClick
        disabled={disabled}
        icon={<CalendarIcon/>}
        dateFormat={showTime ? "yyyy-MM-dd  HH시 mm분" : "yyyy-MM-dd"}
        excludeDateIntervals={excludeDates}
      />
    </div>
  )
}
