import React, {useEffect, useState, useRef} from "react";
import EventEditPage from "./edit";
import EventCreatePage from "./create";
import {Form, Table} from "react-bootstrap";
import Pagination from "common/components/Pagination";
import AdminApi from "common/api";
import {useContext} from "react";
import {AlertContext} from "context/AlertContext";
import {dateFormat} from "common/utils";
import ModalDialog from "common/components/Modal";

export default function EventTab() {

  const api = new AdminApi();
  const {setShow, setToastMsg} = useContext(AlertContext);

  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [checkCategory, setCheckCategory] = useState("POPUP"); // 구분
  const [checkUse, setCheckUse] = useState(true); // 사용여부

  const columns = ["No.", "구분", "순서", "제목", "등록일", "기간", "작성자", "관리"];
  const [data, setData] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);

  const [tableData, setTableData] = useState(null); // 현재 페이지 표 데이터
  const PAGE_SIZE = 15;
  const currentPage = useRef(1);

  const [count, setCount] = useState({playground: 0, pool: 0, yaongcon: 0, profile: 0});

  function fetchData(callbackFunc) {
    api.getEventList({type: checkCategory, active: checkUse}).then((res) => {
      if (res?.infos) {
        setData(res.infos);
        setCount({
          playground: res.infos.filter(el => el.location === "playground").length,
          pool: res.infos.filter(el => el.location === "pool").length,
          yaongcon: res.infos.filter(el => el.location === "yaongcon").length,
          profile: res.infos.filter(el => el.location === "profile").length})
      } else setData(null);
      if (callbackFunc) callbackFunc(res);
    })
  }

  useEffect(() => {
    if (!openEdit && !openCreate) {
      fetchData();
    }
  }, [openCreate, openEdit])

  function onDelete() {
    api.deleteEventInfo({
      eventId: deleteItem.eventId,
      orderNo: deleteItem.orderNo,
      location: deleteItem.location,
      bannerImage: deleteItem.bannerImage,
      useYn: deleteItem.useYn
    })
      .then((res) => {
        if (res?.result === "ok") {
          setToastMsg("삭제되었습니다");
          fetchData();
        } else setToastMsg("실패하였습니다");
        setShow(true);
      })
      .finally(() => {
        setDeleteItem(null);
        setConfirmDelete(false);
      })
  }

  function getLocation(str) {
    switch (str) {
      case "playground": return "놀이터";
      case "pool": return "수영장";
      case "yaongcon": return "야옹콘";
      case "profile": return "프로필";
      default: return "놀이터";
    }
  }

  useEffect(() => {
    if (data) {
      setTableData(data.slice(currentPage.current * PAGE_SIZE - PAGE_SIZE, currentPage.current * PAGE_SIZE));
    } else setTableData(null);
  }, [data])

  function onPageChange(page) {
    currentPage.current = page;
    if (data) {
      setTableData(data.slice(page * PAGE_SIZE - PAGE_SIZE, page * PAGE_SIZE));
    }
  }

  function onSearch() {
    fetchData((data) => {
      if (data?.infos?.length === 0) alert("검색 결과를 찾을 수 없습니다.");
      currentPage.current = 1;
    })
  }

  return (
    <div>
      <h1>이벤트 팝업 등록</h1>
      {!openEdit && !openCreate &&
        <article>
          <div className="flex" style={{marginTop: 40}}>
            <div className="event-checkboxes">
              <div className="filter-checkbox">
                <span>구분</span>
                  <Form.Check
                    type={"checkbox"}
                    id={"POPUP"}
                    label={"이벤트 팝업"}
                    checked={checkCategory==="POPUP"}
                    onChange={(e) => setCheckCategory(e.target.id)}
                  />
              </div>
              <div className="filter-checkbox">
                <span>사용여부</span>
                <div>
                  <Form.Check
                    type={"checkbox"}
                    id={"useYes"}
                    label={"사용"}
                    checked={checkUse}
                    onChange={(e) => setCheckUse(e.target.checked)}
                  />
                  <Form.Check
                    type={"checkbox"}
                    id={"useNo"}
                    label={"미사용"}
                    checked={!checkUse}
                    onChange={(e) => setCheckUse(!e.target.checked)}
                  />
                </div>
              </div>
            </div>
            <button className="blue-btn" type="button" onClick={onSearch}>검색</button>
          </div>

          <button className="blue-btn ml-auto" onClick={() => setOpenCreate(true)}>등록</button>

          <Table bordered className="basic-table event-popup-table">
            <thead>
            <tr>
              {columns.map((col) => (
                <th key={col} colSpan={col === "관리" ? 2 : 1} className="thick-border-top">{col}</th>
              ))}
            </tr>
            </thead>
            <tbody>
            {tableData && tableData.length > 0 ? tableData.map((item, idx) => (
              <tr key={"row"+idx}>
                <td style={{width: 60}}>{idx + 1 + (PAGE_SIZE * (currentPage.current - 1))}</td>
                <td>{getLocation(item.location)}</td>
                <td>{item.orderNo}</td>
                <td>{item.title}</td>
                <td>{dateFormat(item.createdAt, true)}</td>
                <td>{dateFormat(item.exposureStart)} ~ {dateFormat(item.exposureEnd)}</td>
                <td>{item.regUser}</td>
                <td className="button-cell">
                  <button type="button" className="grey-btn"
                          onClick={() => {
                            setEditItem(item);
                            setOpenEdit(true);
                          }}>수정</button>
                </td>
                <td className="button-cell">
                  <button type="button" className="dark-btn"
                          onClick={() => {
                            setDeleteItem(item);
                            setConfirmDelete(true);
                          }}>삭제</button>
                </td>
              </tr>))
              :
              <tr>
                <td colSpan={9}>데이터가 없습니다</td>
              </tr>
            }
            </tbody>
          </Table>
          <Pagination total={data?.length ?? 0} pageSize={PAGE_SIZE}
                      page={currentPage.current} onPageChange={onPageChange}/>

          <ModalDialog show={confirmDelete}
                       onHide={() => {
                         setConfirmDelete(false)
                       }}
                       btnText={"예"} secondBtnText={"아니요"}
                       hasTwoButtons
                       onOk={onDelete}
                       onClickSecond={() => {
                         setConfirmDelete(false)
                       }}
          >
            <p style={{textAlign: 'center'}}>
              삭제하시겠습니까?
            </p>
          </ModalDialog>
        </article>
      }
      {openEdit && <EventEditPage onClose={() => setOpenEdit(false)} data={editItem} count={count}/>}
      {openCreate && <EventCreatePage onClose={() => setOpenCreate(false)} count={count}/>}
    </div>
  )
}