import {useEffect, useState} from "react";
import AdminApi from "common/api";
import ModalDialog from "common/components/Modal";
import React from "react";
import {useContext} from "react";
import {AlertContext} from "context/AlertContext";
import {Table} from "react-bootstrap";
import {dateFormat} from "common/utils";
import Pagination from "common/components/Pagination";
import {useRef} from "react";
import CustomDatePicker from "common/components/DatePicker";
import {format} from "date-fns";

export default function SettingsTab() {

  const api = new AdminApi();
  const {setShow, setToastMsg} = useContext(AlertContext);

  const [configData, setConfigData] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [androidVer, setAndroidVer] = useState("");
  const [iosVer, setIosVer] = useState("");

  const [updOS, setUpdOS] = useState("android");
  const [updVersion, setUpdVersion] = useState("");
  const [updType, setUpdType] = useState("");
  const [updDeployedAt, setUpdDeployedAt] = useState(new Date());

  const columns = ["No", "OS", "버전", "타입", "수정일", "배포일"];
  const [logData, setLogData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const PAGE_SIZE = 15;
  const currentPage = useRef(1);

  function fetchData() {
    api.getConfig().then((data) => {
      if (data?.infos) {
        setConfigData(data.infos);
        setAndroidVer(data.infos.android_version);
        setIosVer(data.infos.ios_version);
      }
    })
    api.getVersionLog().then((data) => {
      if (data?.infos) {
        setLogData(data.infos)
      } else setLogData([]);
    })
  }

  useEffect(() => {
    fetchData();
  }, [])

  function updateVersion() {
    api.updateConfig({
      os: updOS,
      version: updVersion,
      type: updType,
      deployedAt: format(new Date(updDeployedAt), "yyyy-MM-dd hh:mm")
    }).then((res) => {
      if (res?.data?.result === "ok") {
        setToastMsg("저장되었습니다");
        fetchData();
      } else {
        setToastMsg("실패하였습니다");
      }
      setShow(true);
    }).finally(() => {
      setConfirmOpen(false);
      resetUpdInfo();
    })
  }

  useEffect(() => {
    if (logData) {
      setTableData(logData.slice(currentPage.current * PAGE_SIZE - PAGE_SIZE, currentPage.current * PAGE_SIZE));
    } else setTableData(null);
  }, [logData])

  function onPageChange(page) {
    currentPage.current = page;
    if (logData) {
      setTableData(logData.slice(page * PAGE_SIZE - PAGE_SIZE, page * PAGE_SIZE));
    }
  }

  function resetUpdInfo() {
    setUpdOS("android");
    setUpdVersion("");
    setUpdType("");
    setUpdDeployedAt(new Date());
  }

  return (
    <article>
      <h1>앱 버전 관리</h1>

     <div className="app-version-edit">
       <div>
         <span>현재 Android 버전:</span>
         <span>{androidVer}</span>
         <button className="green-btn" onClick={() => {
           setConfirmOpen(true);
           setUpdOS("android");
           setUpdVersion(configData.android_version)
         }}>수정</button>
       </div>
       <div>
         <span>현재 iOS 버전:</span>
         <span>{iosVer}</span>
         <button className="green-btn" onClick={() => {
           setConfirmOpen(true);
           setUpdOS("iOS");
           setUpdVersion(configData.ios_version)
         }}>수정</button>
       </div>
     </div>

      <Table bordered responsive="md" className="basic-table" style={{marginTop: 30}}>
        <thead>
        <tr>
          {columns.map((col) => (
            <th key={col}>{col}</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {tableData ?
          tableData.map((info, idx) => (
            <tr key={"row"+idx}>
              <td style={{width: 80}}>{idx + 1 + (PAGE_SIZE * (currentPage.current - 1))}</td>
              <td>{info.OS ? info.OS.toUpperCase() : ""}</td>
              <td>{info.version ?? ""}</td>
              <td>{info.type ?? ""}</td>
              <td style={{width: 250}}>{info.updated_at ? dateFormat(info.updated_at, true) : ""}</td>
              <td style={{width: 250}}>{info.deployed_at ? dateFormat(info.deployed_at, true) : ""}</td>
            </tr>
          ))
          :
          <tr>
            <td colSpan={6}>데이터가 없습니다</td>
          </tr>
        }
        </tbody>
      </Table>

      <Pagination total={logData?.length ?? 0} pageSize={PAGE_SIZE}
                  page={currentPage.current} onPageChange={onPageChange}/>


      <ModalDialog show={confirmOpen}
                   onHide={() => {
                     setConfirmOpen(false);
                     resetUpdInfo();
                   }}
                   btnText={"저장"} secondBtnText={"취소"}
                   hasTwoButtons
                   onOk={() => updateVersion()}
                   onClickSecond={() => {
                     setConfirmOpen(false);
                     resetUpdInfo()
                   }}
      >
        <div className="version-edit">
          <span className="value-label">OS:</span>
          <input type="text" disabled value={updOS === "android" ? "Android" : "iOS"}/>
          <span className="value-label">버전: </span>
          <input type="text" placeholder={updOS === "android" ? configData.android_version : configData.ios_version}
                 value={updVersion}
                 onChange={(e) => setUpdVersion(e.target.value)}/>
          <span className="value-label">타입: </span>
          <input type="text" value={updType}
                 onChange={(e) => setUpdType(e.target.value)}/>
          <span className="value-label">배포일: </span>
          <CustomDatePicker placeholder="배포일" value={updDeployedAt} showTime
                            onChange={setUpdDeployedAt}/>
        </div>
      </ModalDialog>
    </article>
  )
}