import React, {useState} from "react";

export default function ImageInput(props) {

  const {id = "image-"+Math.random(), defaultImage, onChange} = props;
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(defaultImage ?? "");

  function onUploadFile(e) {
    let inputFile = e.target.files[0];
    if (inputFile) {
      setFile(inputFile);
      onChange(inputFile);

      const reader = new FileReader();
      reader.onloadend = () => {
        setFileUrl(reader.result);
      };
      reader.readAsDataURL(inputFile);
    }
  }

  return (
    <div className="image-input">
      {!fileUrl && !file &&
        <>
          <input id={id} type="file" accept="image/*" onChange={onUploadFile}/>
          <button type="button" className="green-btn"
                  onClick={() => document.getElementById(id).click()}>
            파일 선택
          </button>
        </>
      }
      {fileUrl !== "" &&
        <div>
          <img src={fileUrl} alt="uploaded-input-file" height={140}/>
          <button type="button" className="red-btn"
                  onClick={() => {
                    setFile(null);
                    setFileUrl("");
                    onChange(null);
                  }}>
            이미지 삭제
          </button>
        </div>
      }
    </div>
  )
}