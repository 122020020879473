import {useEffect, useState, useRef} from "react";
import React from "react";
import AdminApi from "common/api";
import CustomDatePicker from "common/components/DatePicker";
import 'react-datepicker/dist/react-datepicker.css';
import TotalTable from '../components/total_table'
import PlatformTable from '../components/platform_table'

export default function Members({isMobile}) {
  const api = new AdminApi();

  const ls = localStorage;
  const sp = useRef(ls.getItem("deliverySearch") ? JSON.parse(ls.getItem("deliverySearch")) : {});
  const [startDt, setStartDt] = useState(sp.current?.startDate ?? null);
  const [endDt, setEndDt] = useState(sp.current?.endDate ?? null);

  return (
    <>
      <div style={{alignItems: "center" ,display: 'flex', marginBottom: '.8em'}}>
        <CustomDatePicker placeholder="시작일" value={startDt}
                          onChange={setStartDt} maxDate={endDt ?? undefined}/>
        <span style={{margin: "0 6px"}}>~</span>
        <CustomDatePicker placeholder="종료일" value={endDt}
                          onChange={setEndDt} minDate={startDt ?? undefined}/>
        {!isMobile && (
          <button className="blue-btn" type="button" style={{ marginLeft: '10px' }}>
            검색
          </button>
        )}
      </div>
      {isMobile && (
        <button className="blue-btn" type="button" style={{ width: '100%' }}>
          검색
        </button>
      )}
      <br/><br/>
      <h5>전체 현황</h5>
      <TotalTable isMobile/>
      <br/><br/>
      <h5>플랫폼별 현황</h5>
      <PlatformTable isMobile/>
    </>
  )
}

