// 오늘 입장
import {Form, Table} from "react-bootstrap";
import React, {useEffect, useState, useContext, useRef} from "react";
import Pagination from "common/components/Pagination";
import {SelectInput} from "common/components/SelectInput";
import ModalDialog from "common/components/Modal";
import AdminApi from "common/api";
import {format, isWithinInterval} from "date-fns";
import {AlertContext} from "context/AlertContext";
import {getReservationType} from "./main";
import {dateFormat} from "common/utils";


/*** 오늘 입장 ***/
export function TodayGuests({priceInfo, updateDashboard}) {

  const api = new AdminApi();
  const ls = localStorage;
  const sp = useRef(ls.getItem("todaySearch") ?
    JSON.parse(ls.getItem("todaySearch")) :
    {params: { status: "RESERVED", showToday: true,
      startDate: format(new Date(), "yyyy-MM-dd 00:00:00"),
        endDate: format(new Date(), "yyyy-MM-dd 23:59:59")}});

  const timeTypes = ["daytime", "nighttime", "allDay"];
  const timeTypesKo = ["주간", "야간", "종일"];
  const columns = ["No.", "예약자 이름", "전화번호", "예약 신청 날짜", "입장일", "예약번호", "주/야간", " 인원", "평상", "평상 가격", "쿠폰 사용 유무"];

  const {setShow, setToastMsg} = useContext(AlertContext);
  const [checked, setChecked] = useState(sp.current?.params.reservedTimeType ? timeTypes[sp.current?.params.reservedTimeType-1] : "all");
  const [showOnlyDone, setShowOnlyDone] = useState(sp.current?.params.status ? sp.current?.params.status === "DONE" : false);
  const [currentShowDone, setCurrentShowDone] = useState(showOnlyDone); // 입장 완료 고객만 보기
  const [data, setData] = useState(null);

  const [addData, setAddData] = useState([]); // 추가 인원, 추가 결제, 메모 값
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);

  const todayPrice = priceInfo?.filter(el => isWithinInterval(new Date(), {start: new Date(el.beginDt), end: new Date(el.endDt)}))[0];
  const aPrice = {1: todayPrice?.adult?.[0] ?? 0, 2: todayPrice?.adult?.[1] ?? 0, 3: todayPrice?.adult?.[2] ?? 0};
  const jPrice = {1: todayPrice?.junior?.[0] ?? 0, 2: todayPrice?.junior?.[1] ?? 0, 3: todayPrice?.junior?.[2] ?? 0};

  const [tableData, setTableData] = useState(null); // 현재 페이지 표 데이터
  const PAGE_SIZE = 15;
  const currentPage = useRef(1);
  const [totalCount, setTotalCount] = useState(0);

  const selectItems = [
    {value: "name", text: "예매자명"},
    {value: "phone", text: "전화번호"},
    {value: "bookId", text: "예매번호"},
    {value: "bedLocation", text: "평상번호"}
  ]

  const [keywordType, setKeywordType] = useState(sp.current?.selectedKeyType ?? selectItems[0]);
  const [keyword, setKeyword] = useState(sp.current?.selectedKeyType ? sp.current[sp.current.selectedKeyType.value] : "");

  const filters = [
    {id: "all", label: "전체"},
    {id: "daytime", label: "주간"},
    {id: "nighttime", label: "야간"},
    {id: "allDay", label: "종일"}
  ]

  function onCheck(e) {
    let id = e.target.id;
    setChecked(id);
  }

  function updateBookingInfo() {
    let bookingInfo = addData[updateItem.index];
    let params = {
      bookNo: updateItem.id,
      addAdult: bookingInfo?.addAdult ? parseInt(bookingInfo?.addAdult) : null,
      addJunior: bookingInfo?.addJunior ? parseInt(bookingInfo?.addJunior) : null,
      addBill: bookingInfo?.addBill ? parseInt(bookingInfo?.addBill) : null,
      memo: bookingInfo?.memo ?? null,
      status: "DONE"
    }
    api.updateBooking(params).then((res) => {
      if (res?.data?.result === "ok") {
        setToastMsg("저장되었습니다");
        setAddData(prev => {
          return prev.filter((val, idx) => idx !== updateItem.index)
        });
        fetchData();
      } else {
        setToastMsg("실패하였습니다");
      }
      setShow(true);
    }).finally(() => {
      setConfirmUpdate(false);
      setUpdateItem(null);
      updateDashboard();
    })
  }

  function resetAddData(infos) {
    let tempArr = [];
    infos?.forEach(() => {
      tempArr.push({addAdult: 0, addJunior: 0, addBill: 0, memo: ""})
    })
    setAddData(tempArr);
  }

  function fetchData(callbackFunc) {
    api.getBookingList(sp.current.params)
      .then((res) => {
        if (res?.infos) {
          setData(res.infos);
          setTotalCount(res.infos.length);
        } else {
          setData(null);
          setTotalCount(0);
        }
        if (callbackFunc) callbackFunc(res);
      })
  }

  function onSearch() {
    currentPage.current = 1;
    let params = {
      showToday: true,
      status: showOnlyDone ? "DONE" : "RESERVED",
      [keywordType.value]: keyword!=="" ? keyword : null,
      startDate: format(new Date(), "yyyy-MM-dd 00:00:00"),
      endDate: format(new Date(), "yyyy-MM-dd 23:59:59"),
      reservedTimeType: checked === "all" ? null : getReservationType(checked)
    }
    if (currentShowDone !== showOnlyDone) setCurrentShowDone(showOnlyDone);
    ls.setItem("todaySearch", JSON.stringify({params: params, selectedKeyType: keywordType}));
    sp.current = {params: params, selectedKeyType: keywordType};
    fetchData((data) => {
      if (data?.infos?.length === 0) alert("검색 결과를 찾을 수 없습니다.");
      resetAddData(data?.infos);
    });
  }


  useEffect(() => {
    fetchData((data) => {
      resetAddData(data?.infos);
    });
  }, [])


  function onChangeAddData(e, index, valueName, type) {
    let arr = Array.from(addData);
    let value = e.target.value;
    arr[index][valueName] = value;
    if (type) {
      let billSum = 0;
      if (valueName === "addAdult") billSum = parseInt(value) * aPrice[type] + parseInt(arr[index].addJunior) * jPrice[type];
      if (valueName === "addJunior") billSum = parseInt(value) * jPrice[type] + parseInt(arr[index].addAdult) * aPrice[type];
      arr[index].addBill = billSum;
    }
    setAddData(arr);
  }

  useEffect(() => {
    if (data) {
      setTableData(data.slice(currentPage.current * PAGE_SIZE - PAGE_SIZE, currentPage.current * PAGE_SIZE));
    } else setTableData(null);
  }, [data])

  function onPageChange(page) {
    currentPage.current = page;
    if (data) {
      setTableData(data.slice(page * PAGE_SIZE - PAGE_SIZE, page * PAGE_SIZE));
    }
  }

  return (
    <article>
      <div className="flex" style={{gap: 20, marginBottom: 20}}>
        <SelectInput placeholder="입력 후 검색하세요"
                     selected={keywordType}
                     onSelect={setKeywordType}
                     onChange={setKeyword}
                     defaultValue={keyword!==""?keyword:null}
                     selectItems={selectItems}
        />
        <div className="filter-checkbox">
          <span>구분</span>
          <div>
            {filters.map((item) => (
              <Form.Check
                key={"체크-"+item.label}
                type={"checkbox"}
                id={item.id}
                label={item.label}
                checked={checked === item.id}
                onChange={onCheck}
              />
            ))}
            <Form.Check
              type={"checkbox"}
              id={"clientsInside"}
              label={"입장 완료 고객"}
              checked={showOnlyDone}
              onChange={(e) => setShowOnlyDone(e.target.checked)}
            />
          </div>
        </div>
        <button className="blue-btn" type="button" onClick={onSearch}>검색</button>
        <span className="text-grey bold" style={{marginTop: 10}}>예약 건 수: {totalCount}</span>
      </div>

      <Table bordered className="basic-table today-visit-table">
        <thead>
        <tr>
          {columns.map((col) => (
            <th key={col} className="thick-border-top">{col}</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {(tableData && tableData.length > 0) ? tableData.map((info, idx) => (
          <React.Fragment key={"row"+idx}>
            <tr>
              <td rowSpan={4} className="thick-border bold" style={{width: 60}}>{idx + 1 + (PAGE_SIZE * (currentPage.current - 1))}</td>
              <td>{info.name}</td>
              <td>{info.phone}</td>
              <td style={{width: 180}}>{dateFormat(info.createdAt, true)}</td>
              <td style={{width: 180}}>{info.reservedDate}</td>
              <td>{info.bookId}</td>
              <td>{timeTypesKo[info.reservedTimeType-1]}</td>
              <td>대인 {info.adult ?? 0}{info.junior ? ", 소인 " + info.junior : ""}</td>
              <td>{info.bedLocation &&
                JSON.parse(info.bedLocation).map((b,idx) => (
                  <React.Fragment key={idx+"-"+b}>{b}번{idx<JSON.parse(info.bedLocation).length-1 && ", "}</React.Fragment>
                ))}</td>
              <td>{info.bookingBill?.toLocaleString() ?? ""}</td>
              <td>{info.couponDc && info.couponDc!== 0 ? info.couponDc : ""}</td>
            </tr>
            <tr style={{textAlign: 'left'}}>
              <td colSpan={7}>
                <div>
                  <span>예약자 입장 유무</span>
                  {info.status === "RESERVED" &&
                    <button type="button" className="green-btn"
                            onClick={() => {
                              setUpdateItem({id: info.bookNo, index: (currentPage.current-1) * PAGE_SIZE + idx});
                              setConfirmUpdate(true);
                            }}>예</button>}
                  {info.status === "DONE" && <button type="button" className="grey-btn status-btn">입장 완료</button>}
                </div>
              </td>
              <td colSpan={3} className="bold">메모</td>
            </tr>
            <tr style={{textAlign: 'left'}}>
              <td colSpan={3}>
                <div className="guest-number">
                  <p>함께 입장한 인원</p>
                  <div>
                    <span>대인</span>
                    <span className="number-box">{info.adult}</span>
                    <span>소인</span>
                    <span className="number-box">{info.junior}</span>
                  </div>
                </div>
              </td>
              <td colSpan={4}>
                <div>
                  <span>남은 현장 결제</span>
                  <span className="price-box">{(info.onsiteBillAdult + info.onsiteBillJunior).toLocaleString()}원</span>
                </div>
              </td>
              <td colSpan={3} rowSpan={2} className="thick-border">
                {!currentShowDone ?
                  <textarea name="memo" id="memo" rows="3"
                            onChange={(e) => onChangeAddData(e, (currentPage.current-1) * PAGE_SIZE + idx, "memo")}
                            value={addData[(currentPage.current-1) * PAGE_SIZE + idx]?.memo}
                            maxLength={100} placeholder="메모를 입력하세요 (100글자까지)"/>
                  :
                  <p>{info.memo ?? ""}</p>
                }
              </td>
            </tr>
            <tr style={{textAlign: 'left'}}>
              <td colSpan={3} className="thick-border">
                <div className="guest-number">
                  <p>추가 인원</p>
                  {!currentShowDone ?
                    <div>
                      <span>대인</span>
                      <input type="number" className="number-box" min={0}
                             onChange={(e) => onChangeAddData(e, (currentPage.current-1) * PAGE_SIZE + idx, "addAdult", info.reservedTimeType)}
                             value={addData[(currentPage.current-1) * PAGE_SIZE + idx]?.addAdult}/>
                      <span>소인</span>
                      <input type="number" className="number-box" min={0}
                             onChange={(e) => onChangeAddData(e, (currentPage.current-1) * PAGE_SIZE + idx, "addJunior", info.reservedTimeType)}
                             value={addData[(currentPage.current-1) * PAGE_SIZE + idx]?.addJunior}/>
                    </div>
                    :
                    <div>
                      <span>대인</span>
                      <span className="number-box">{info.addAdult}</span>
                      <span>소인</span>
                      <span className="number-box">{info.addJunior}</span>
                    </div>
                  }
                </div>
              </td>
              <td colSpan={4} className="thick-border">
                <div>
                  <span>추가 결제</span>
                  {!currentShowDone ?
                    <span className="price-box">
                      {addData[(currentPage.current-1) * PAGE_SIZE + idx]?.addBill?.toLocaleString() ?? "0"}원</span>
                    : <span className="price-box">{info.addBill ? info.addBill.toLocaleString() : "0"}원</span>
                  }
                </div>
              </td>
            </tr>
          </React.Fragment>
          ))
          :
          <tr>
            <td colSpan={10}>데이터가 없습니다</td>
          </tr>
        }
        </tbody>
      </Table>

      <Pagination total={data?.length ?? 0} page={currentPage.current}
                  pageSize={PAGE_SIZE} onPageChange={onPageChange}/>

      <ModalDialog show={confirmUpdate}
                   title={"확인"}
                   onHide={() => {
                     setConfirmUpdate(false);
                     setUpdateItem(null);
                   }}
                   btnText={"예"}
                   hasTwoButtons
                   secondBtnText={"아니요"}
                   onOk={updateBookingInfo}
                   onClickSecond={() => {
                     setUpdateItem(null);
                     setConfirmUpdate(false);
                   }}
      >
        <p style={{textAlign: 'center'}}>
          입장했나요?
        </p>
      </ModalDialog>
    </article>
  )
}