import React from 'react';
import { Table } from 'antd';

const columns = [
  {
    title: '날짜',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
  },
  {
    title: '신규유저',
    children: [
      {
        title: '모바일 웹',
        dataIndex: 'newUserMobileWeb',
        key: 'newUserMobileWeb',
        align: 'center',
      },
      {
        title: 'PC 웹',
        dataIndex: 'newUserPCWeb',
        key: 'newUserPCWeb',
        align: 'center',
      },
      {
        title: '안드로이드',
        dataIndex: 'newUserAndroid',
        key: 'newUserAndroid',
        align: 'center',
      },
      {
        title: '애플',
        dataIndex: 'newUserApple',
        key: 'newUserApple',
        align: 'center',
      },
      {
        title: '합계',
        dataIndex: 'newUserTotal',
        key: 'newUserTotal',
        align: 'center',
      },
    ],
  },
  {
    title: 'DAU',
    children: [
      {
        title: '모바일 웹',
        dataIndex: 'dauMobileWeb',
        key: 'dauMobileWeb',
        align: 'center',
      },
      {
        title: 'PC 웹',
        dataIndex: 'dauPCWeb',
        key: 'dauPCWeb',
        align: 'center',
      },
      {
        title: '안드로이드',
        dataIndex: 'dauAndroid',
        key: 'dauAndroid',
        align: 'center',
      },
      {
        title: '애플',
        dataIndex: 'dauApple',
        key: 'dauApple',
        align: 'center',
      },
      {
        title: '합계',
        dataIndex: 'dauTotal',
        key: 'dauTotal',
        align: 'center',
      },
    ],
  },
];

const data = [
  {
    key: '1',
    date: '2024-05-01',
    newUserMobileWeb: 100,
    newUserPCWeb: 99,
    newUserAndroid: 150,
    newUserApple: 200,
    newUserTotal: 450,
    dauMobileWeb: 800,
    dauPCWeb: 98,
    dauAndroid: 900,
    dauApple: 1000,
    dauTotal: 2700,
  },
];

const TableComponent = ({isMobile}) => {
  return <Table 
    columns={columns} 
    dataSource={data}
    scroll={{ x: isMobile?900:undefined }}
    pagination={false} 
  />;
};

export default TableComponent;