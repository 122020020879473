import axios from "axios";

export default class AdminApi {

  constructor() {
    this.client = null;
    this.SERVER_URL = "https://api.yaongstore.com";
  }

  api = (options) => {
    let token = localStorage.getItem("ACCESS_TOKEN");
    let headers = {
      Accept: "application/json",
      withCredentials: false,
    };
    if (token && !options?.headers?.Authorization) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    if (options?.contentType) {
      headers["Content-Type"] = options.contentType;
    }
    if (options?.headers?.Authorization) {
      headers["Authorization"] = options.headers.Authorization;
    }
    this.client = axios.create({
      baseURL: this.SERVER_URL,
      timeout: 60000,
      headers: headers
    });
    return this.client;
  };

  signIn = (username, password) => {
    return this.api().post("/auth/login-admin", {username: username, password: password})
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  };

  refreshToken = (userNo, tokenInfo) => {
    return this.api({
      headers: {
        Authorization: `RefreshToken ${tokenInfo["refreshToken"]}`
      }}).get(`/auth/token/refresh?user_no=${userNo}`)
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }

  getGameRoomList = (params) => {
    return this.api().get("/game/admin/rooms", {params: params})
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  };

  createGameRoom = (params, file) => {
    let formData = new FormData();
    Object.keys(params).forEach((name) => {
      formData.append(name, params[name]);
    })
    if (file) formData.append("goodsImage", file);
    return this.api({contentType: 'multipart/form-data'}).post("/game/admin/rooms", formData)
      .then((res) => {
        if (res?.data?.result === "fail") {
          console.error(res?.data?.message);
        }
        return res;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  };

  updateGameRoom = (params, file) => {
    let formData = new FormData();
    Object.keys(params).forEach((name) => {
      formData.append(name, params[name]);
    })
    if (file) formData.append("goodsImage", file);

    return this.api({contentType: 'multipart/form-data'}).patch("/game/admin/rooms", formData)
      .then((res) => {
        if (res?.data?.result === "fail") {
          console.error(res?.data?.message);
        }
        return res;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  };

  deleteGameRoom = (roomId) => {
    return this.api().delete(`/game/admin/rooms?roomId=${roomId}`)
      .then((res) => {
        if (res?.data?.result === "fail") {
          console.error(res?.data?.message);
        }
        return res;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  };


  getDeliveryInfoList = (params) => {
    return this.api().get("/delivery/admin/list", {params: params})
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  };

  updateDeliveryStatus = (id) => {
    return this.api().patch("/delivery/admin/status", {deliveryId: id, deliveryStatus: "SENT"})
      .then((res) => {
        if (res?.data?.result === "fail") {
          console.error(res?.data?.message);
        }
        return res;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  };

  getBookingList = (params) => {
    return this.api().get("/pool/admin/book", {params: params})
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  };

  getBookingStats = () => {
    return this.api().get("/pool/admin/book/stats")
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  };

  getPoolInfo = () => {
    return this.api().get("/pool/info")
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }

  updatePoolInfo = (params) => {
    return this.api().patch("/pool/admin/info", {...params})
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }

  addPoolPrice = (params) => {
    return this.api().post("/pool/admin/price", {...params})
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }
  updatePoolPrice = (params) => {
    return this.api().patch("/pool/admin/price", {...params})
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }
  deletePoolPrice = (id) => {
    return this.api().delete(`/pool/admin/price?poolPriceId=${id}`)
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }

  updateBooking = (params) => {
    return this.api().patch("/pool/admin/book", {...params})
      .then((res) => {
        if (res?.data?.result === "fail") {
          console.error(res?.data?.message);
        }
        return res;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }

  getEventList = (params) => {
    return this.api().get("/event/list", {params: params})
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }

  createEvent = (params, file) => {
    let formData = new FormData();
    Object.keys(params).forEach((name) => {
      formData.append(name, params[name]);
    })
    if (file) formData.append("bannerImage", file);
    return this.api({contentType: 'multipart/form-data'}).post("/event/admin", formData)
      .then((res) => {
        if (res?.data?.result === "fail") {
          console.error(res?.data?.message);
        }
        return res;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  };

  updateEventInfo = (params, file) => {
    let formData = new FormData();
    Object.keys(params).forEach((name) => {
      formData.append(name, params[name]);
    })
    if (file) formData.append("newBannerImage", file);
    return this.api({contentType: 'multipart/form-data'}).patch("/event/admin", formData)
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }

  deleteEventInfo = (params) => {
    return this.api().delete(`/event/admin`, {params: params})
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }

  sendPushMessage = (params) => {
    return this.api().post("/fcm/send", {...params})
      .then((res) => {
        return res;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }

  getConfig = () => {
    return this.api().get("/auth/config")
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }

  getVersionLog = () => {
    return this.api().get("/auth/admin/version")
      .then((res) => {
        return res?.data;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }

  updateConfig = (params) => {
    return this.api().patch("/auth/admin/version", {...params})
      .then((res) => {
        if (res?.data?.result === "fail") {
          console.error(res?.data?.message);
        }
        return res;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }
}


export class KakaoApi {

  constructor() {
    this.client = null;
    this.authToken = "NGWzcT0Vx1d79Z3xLEDNxw=="; // API 호출 키
    this.serverName = "yaongmaster"; // API 호출 아이디
    this.SERVER_URL = "https://talkapi.lgcns.com/request/kakao.json";
  }

  api = () => {
    let headers = {
      Accept: "application/json",
      authToken: this.authToken,
      serverName: this.serverName,
      paymentType: "P"
    };
    this.client = axios.create({
      baseURL: this.SERVER_URL,
      timeout: 60000,
      headers: headers
    });
    return this.client;
  };

  // 예약 확정 알림 보내기
  sendBookingConfirmation = (date, phoneNumber, useTime, bedLocation) => {
    let params = {
      service: 2400103893,
      message: `[야옹상회]\n예약이 확정 되었어요. 그날 봐요!! \n예약 날짜 : ${date}\n이용 시간 : ${useTime}\n평상 번호 : ${bedLocation}\n▶︎ 주의 사항\n- 반짝이(글리터), 동물 튜브 및 120cm 이상 튜브 사용 금지\n- 병 반입 금지\n- 머리 덮는 모자 필수\n- 돗자리 필수\n▶︎고객센터\n수영장 예약관련 문의나 상담은 수영장 고객센터로 연락부탁드립니다. 야옹상회 고객센터는 수영장 관련 대응이 어렵습니다.\n수영장 이용 문의 : 010-2138-3563\n\n야옹상회 서비스 문의: 031-319-3087`,
      mobile: phoneNumber,
      template: "10011",
      title: "예약이 확정 되었어요!",
      buttons:[
        {name: "예약 확인하기", type: "WL", url_mobile: "https://app.yaongstore.com/pool/ticket"}
      ]
    }
    return this.api().post("/", {...params})
      .then((res) => {
        return res;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }

  // 예약 취소 알림 보내기
  sendCancelConfirmation = (date, phoneNumber) => {
    let params = {
      service: 2400103893,
      message: `[야옹상회]\n신청 하신 ${date} 예약이 정상적으로 취소 되었어요.\n예약금은 매월 15일 / 말일에 일괄적으로 환불해 드려요.\n\n-수영장 고객센터: 010-2138-3563\n- 수영장 예약 관련 문의나 상담은 수영장 고객센터로 연락 부탁드립니다. 야옹상회 고객센터는 수영장 관련 대응이 어렵습니다.\n- 야옹상회 고객센터: 031-319-3087`,
      mobile: phoneNumber,
      template: "10007",
      title: "예약이 취소 되었어요!",
      buttons:[
        {name: "확인하기", type: "WL", url_mobile: "https://app.yaongstore.com/pool/ticket"}
      ]
    }
    return this.api().post("/", {...params})
      .then((res) => {
        return res;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }

  // 상품 배송 알림 보내기
  sendDeliveryConfirmation = (username, nickname, phoneNumber) => {
    let params = {
      service: 2400103893,
      message: `[야옹상회]\n${username}(${nickname})님의 "토리를 구해줘" 우승 상품 배송이 시작 되었어요.\n\n- 고객센터: 031-319-3087`,
      mobile: phoneNumber,
      template: "10003",
      title: "토리를 구해줘 우승 상품 배송!",
      buttons:[
        {name: "야옹상회 바로가기", type: "WL", url_mobile: "https://app.yaongstore.com/playground"}
      ]
    }
    return this.api().post("/", {...params})
      .then((res) => {
        return res;
      }).catch((err) => {
        console.error(err.response?.data?.message ?? err);
      });
  }
}