import React, {useContext, useState} from "react";
import {AuthContext} from "context/AuthContext";
import GameTab from "pages/game/main";
import PoolTab from "pages/pool/main";
import EventTab from "pages/event/main";
import StatisticTab from "pages/statistics/main";
import {useNavigate} from 'react-router';
import ModalDialog from "common/components/Modal";
import Toast from "common/components/ToastAlert";
import {AlertContext} from "context/AlertContext";
import SettingsTab from "pages/settings/main";

export default function Layout({selected}) {
  const {auth, signOut, isMobile} = useContext(AuthContext);
  const {show, setShow, toastMsg, setToastMsg} = useContext(AlertContext);
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);

  return (
    <main className={(isMobile && location.pathname === "/statistic") ? 'mobile' : 'desktop'}>
      {auth &&
        <React.Fragment>
          <div className="flex">
            <nav className="navigation-tabs">
              <div className={selected === 1 ? "selected-page" : ""} onClick={() => navigate("/game")}>토리를 구해줘</div>
              <div className={selected === 2 ? "selected-page" : ""} onClick={() => navigate("/pool")}>수영장 관리</div>
              <div className={selected === 3 ? "selected-page" : ""} onClick={() => navigate("/event")}>이벤트 팝업</div>
              <div className={selected === 4 ? "selected-page" : ""} onClick={() => navigate("/statistic")}>통계</div>
              <div className={selected === 5 ? "selected-page" : ""} onClick={() => navigate("/settings")}>설정</div>
            </nav>
            <button type="button" className="logout-button" onClick={() => setConfirmOpen(true)}>
              <LogoutIcon/>
              <span>로그아웃</span>
            </button>
          </div>

          <section className="page-content">
            {selected === 1 && <GameTab/>}
            {selected === 2 && <PoolTab/>}
            {selected === 3 && <EventTab/>}
            {selected === 4 && <StatisticTab/>}
            {selected === 5 && <SettingsTab/>}
          </section>

          <ModalDialog show={confirmOpen}
                       title={"확인"}
                       onHide={() => setConfirmOpen(false)}
                       btnText={"예"}
                       hasTwoButtons
                       secondBtnText={"아니요"}
                       onOk={signOut}
                       onClickSecond={() => setConfirmOpen(false)}>
            <p style={{textAlign: 'center'}}>
              로그아웃 하시겠습니까?
            </p>
          </ModalDialog>
          <Toast show={show}
                 onClose={() => {
                   setShow(false);
                   setToastMsg("");
                 }}
                 text={toastMsg}/>
        </React.Fragment>
      }
    </main>
  )
  function LogoutIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height={isMobile?"25px":"20px"} width={isMobile?"25px":"20px"} version="1.1" viewBox="0 0 490.3 490.3">
        <g>
          <g>
            <path fill="#999999" d="M0,121.05v248.2c0,34.2,27.9,62.1,62.1,62.1h200.6c34.2,0,62.1-27.9,62.1-62.1v-40.2c0-6.8-5.5-12.3-12.3-12.3    s-12.3,5.5-12.3,12.3v40.2c0,20.7-16.9,37.6-37.6,37.6H62.1c-20.7,0-37.6-16.9-37.6-37.6v-248.2c0-20.7,16.9-37.6,37.6-37.6h200.6    c20.7,0,37.6,16.9,37.6,37.6v40.2c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-40.2c0-34.2-27.9-62.1-62.1-62.1H62.1    C27.9,58.95,0,86.75,0,121.05z"/>
            <path fill="#999999" d="M385.4,337.65c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l83.9-83.9c4.8-4.8,4.8-12.5,0-17.3l-83.9-83.9    c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l63,63H218.6c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3h229.8l-63,63    C380.6,325.15,380.6,332.95,385.4,337.65z"/>
          </g>
        </g>
      </svg>
    )
  }
}
