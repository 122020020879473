import {Form, Table} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import CustomDatePicker from "common/components/DatePicker";
import AdminApi from "common/api";
import {format, subDays} from "date-fns";
import {useContext} from "react";
import {AlertContext} from "context/AlertContext";
import ModalDialog from "common/components/Modal";

const DT_FORMAT = "yyyy-MM-dd";

/*** 평상 및 입장권 가격 ***/
export function PriceManagement({initPrice, updatePrice}) {

  const [showAddNew, setShowAddNew] = useState(false);
  const [excludeDates, setExcludeDates] = useState([]);

  function onClickCreate() {
    setShowAddNew(true);
  }

  useEffect(() => {
    if (showAddNew) {
      const pageEl = document.getElementsByClassName("page-content")[0];
      pageEl.scrollTop = pageEl.scrollHeight;
    }
  }, [showAddNew])

  useEffect(() => {
    if (initPrice && initPrice.length > 0) {
      let temp = [];
      initPrice.forEach((price) => temp.push({start:format(subDays(new Date(price.beginDt), 1), DT_FORMAT), end: price.endDt}));
      setExcludeDates(temp);
    }
  }, [initPrice])

  return (
    <article className="price-tab-wrapper">
      <button type="button" className="green-btn" onClick={onClickCreate} style={{width: 200}}>가격표 추가</button>
      {initPrice?.map((price) => (
        <PriceSetting key={"price-id-"+price.poolPriceId} initPrice={price} updatePrice={updatePrice} excludeDates={excludeDates}/>
      ))}

      {showAddNew &&
        <PriceSetting updatePrice={updatePrice} isCreating={true} excludeDates={excludeDates}
                      onCancelCreate={() => setShowAddNew(false)}/>
      }
    </article>
  )
}

/*** 가격표 컴포넌트 ***/
function PriceSetting({initPrice, updatePrice, isCreating = false, excludeDates, onCancelCreate = () => {}}) {

  const api = new AdminApi();
  const {setShow, setToastMsg} = useContext(AlertContext);

  const [priceInfo, setPriceInfo] = useState(isCreating ? null : initPrice);

  const selectedPeriod = !isCreating && initPrice ? {start: format(subDays(new Date(initPrice.beginDt), 1), DT_FORMAT), end: initPrice.endDt} : {};
  const [startDt, setStartDt] = useState(priceInfo ? priceInfo.beginDt : null);
  const [endDt, setEndDt] = useState(priceInfo ? priceInfo.endDt : null);

  const priceBeforeUpdate = useRef([
    {day: priceInfo?.bed?.[0] ?? 0, night: priceInfo?.bed?.[1] ?? 0, allDay: priceInfo?.bed?.[2] ?? 0},
    {day: priceInfo?.bungalow?.[0] ?? 0, night: priceInfo?.bungalow?.[1] ?? 0, allDay: priceInfo?.bungalow?.[2] ?? 0},
    {day: priceInfo?.adult?.[0] ?? 0, night: priceInfo?.adult?.[1] ?? 0, allDay: priceInfo?.adult?.[2] ?? 0},
    {day: priceInfo?.junior?.[0] ?? 0, night: priceInfo?.junior?.[1] ?? 0, allDay: priceInfo?.junior?.[2] ?? 0}
  ])

  const [benchPrice, setBenchPrice] = useState(priceBeforeUpdate.current[0]);
  const [bungalowPrice, setBungalowPrice] = useState(priceBeforeUpdate.current[1]);
  const [adultPrice, setAdultPrice] = useState(priceBeforeUpdate.current[2]);
  const [juniorPrice, setJuniorPrice] = useState(priceBeforeUpdate.current[3]);

  const [nightOption, setNightOption] = useState(priceInfo ? priceInfo.nightOption : "weekday");
  const [nightShow, setNightShow] = useState(priceInfo ? priceInfo.nightShow === "Y" : false); // 야간 노출

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(isCreating ?? false);

  const priceList = [
    {name: "* 평상 가격", price: benchPrice, onChange: setBenchPrice},
    {name: "* 방갈로 가격", price: bungalowPrice, onChange: setBungalowPrice},
    {name: "* 대인 가격", price: adultPrice, onChange: setAdultPrice},
    {name: "* 소인 가격", price: juniorPrice, onChange: setJuniorPrice}
  ]

  function onSave() {
    if (!startDt || !endDt) {
      setConfirmOpen(false);
      alert("기간을 선택해주세요");
      return;
    }
    if ([...Object.values(benchPrice), ...Object.values(bungalowPrice), ...Object.values(adultPrice), ...Object.values(juniorPrice)]
      .filter((el) => el === "" || el === undefined || (typeof el === "number" && isNaN(el))).length > 0) {
      setConfirmOpen(false);
      alert("필수 항목 모두 입력해주세요");
      return;
    }

    let updatedPrice = {
      bed:      [benchPrice.day, benchPrice.night, benchPrice.allDay],
      bungalow: [bungalowPrice.day, bungalowPrice.night, bungalowPrice.allDay],
      adult:    [adultPrice.day, adultPrice.night, adultPrice.allDay],
      junior:   [juniorPrice.day, juniorPrice.night, juniorPrice.allDay],
    }
    let params = {
      price: JSON.stringify(updatedPrice),
      beginDt: format(startDt, DT_FORMAT),
      endDt: format(endDt, DT_FORMAT),
      nightOption: nightOption,
      nightShow: nightShow ? "Y" : "N"
    }
    if (!isCreating) {
      params.poolPriceId = initPrice.poolPriceId
    }

    if (isCreating) {
      api.addPoolPrice(params)
        .then((res) => {
          if (res?.result === "ok") {
            setToastMsg("등록되었습니다");
            updatePrice();
            setPriceInfo(updatedPrice);
            priceBeforeUpdate.current = [benchPrice, bungalowPrice, adultPrice, juniorPrice];
            setIsEditing(false);
          } else {
            setToastMsg("실패하였습니다." + (res?.message ? " 오류: " + res.message : ""));
          }
          setShow(true);
        })
        .finally(() => {
          setConfirmOpen(false);
          onCancelCreate();
        })
    } else {
      api.updatePoolPrice(params)
        .then((res) => {
          if (res?.result === "ok") {
            setToastMsg("수정되었습니다");
            updatePrice();
            setPriceInfo(updatedPrice);
            priceBeforeUpdate.current = [benchPrice, bungalowPrice, adultPrice, juniorPrice];
            setIsEditing(false);
          } else {
            setToastMsg("실패하였습니다");
          }
          setShow(true);
        })
        .finally(() => {
          setConfirmOpen(false);
        })
    }

  }

  function onCancel() {
    setBenchPrice(priceBeforeUpdate.current[0]);
    setBungalowPrice(priceBeforeUpdate.current[1]);
    setAdultPrice(priceBeforeUpdate.current[2]);
    setJuniorPrice(priceBeforeUpdate.current[3]);
    setNightShow(priceInfo ? priceInfo.nightShow === "Y" : false);
    setIsEditing(false);
    if (isCreating) onCancelCreate();
  }

  function onDelete() {
    api.deletePoolPrice(initPrice.poolPriceId)
      .then((res) => {
        if (res?.result === "ok") {
          setToastMsg("삭제되었습니다");
          updatePrice();
        } else {
          setToastMsg("실패하였습니다." + (res?.message ? " 오류: " + res.message : ""));
        }
        setShow(true);
      })
      .finally(() => {
        setDeleteConfirmOpen(false)
      })
  }

  return (
    <>
      <Table bordered className={`basic-table price-table${isCreating ? " new-price-table" : ""}`}>
        <thead>
        <tr>
          <th style={{minWidth: 140}}>구분</th>
          <th style={{width: "30%"}}>주간</th>
          <th style={{width: "30%"}}>
            야간
            <div className={`flex-center${!isEditing ? " disabled-check" : ""}`} style={{gap: 30}}>
              <Form.Check
                type={"checkbox"}
                id={"onlyWeekend"}
                label={"주말만"}
                disabled={!isEditing || !nightShow}
                checked={nightShow ? nightOption === "weekend" : false}
                onChange={() => setNightOption("weekend")}
              />
              <Form.Check
                type={"checkbox"}
                id={"onlyWeekday"}
                label={"평일만"}
                disabled={!isEditing || !nightShow}
                checked={nightShow ? nightOption === "weekday" : false}
                onChange={() => setNightOption("weekday")}
              />
              <Form.Check
                type={"checkbox"}
                id={"wholeWeek"}
                label={"평일/주말"}
                disabled={!isEditing || !nightShow}
                checked={nightShow ? nightOption === "wholeWeek" : false}
                onChange={() => setNightOption("wholeWeek")}
              />
            </div>
          </th>
          <th style={{width: "30%"}}>종일</th>
        </tr>
        </thead>

        <tbody>
        <tr>
          <td className="bold">* 기간</td>
          <td colSpan={3}>
            <div className="date-period">
              <CustomDatePicker placeholder="시작일" value={startDt}
                                disabled={!isEditing}
                                onChange={setStartDt}
                                excludeDates={isCreating ? excludeDates : excludeDates.filter(el => (el.start !== selectedPeriod.start && el.end !== selectedPeriod.end))}
                                maxDate={endDt ?? undefined}/>
              <span style={{margin: "0 6px"}}>~</span>
              <CustomDatePicker placeholder="종료일" value={endDt}
                                disabled={!isEditing}
                                onChange={setEndDt}
                                excludeDates={isCreating ? excludeDates : excludeDates.filter(el => (el.start !== selectedPeriod.start && el.end !== selectedPeriod.end))}
                                minDate={startDt ?? undefined}/>
            </div>
          </td>
        </tr>
        {priceList.map((item, idx) => (
          <tr key={"price-row-="+idx}>
            <td className="bold">{item.name}</td>
            <td style={{textAlign: "left"}}>
              <input type="number" value={item.price.day} step={1000} min={0} disabled={!isEditing}
                     onChange={(e) => item.onChange({...item.price, day: parseInt(e.target.value)})}/>
            </td>
            <td style={{textAlign: "left"}}>
              <input type={nightShow ? "number" : "text"} value={nightShow ? item.price.night : "-"} step={1000} min={0} disabled={!isEditing || !nightShow}
                     onChange={(e) => item.onChange({...item.price, night: parseInt(e.target.value)})}/>
            </td>
            <td style={{textAlign: "left"}}>
              <input type={nightShow ? "number" : "text"} value={nightShow ? item.price.allDay : "-"} step={1000} min={0} disabled={!isEditing || !nightShow}
                     onChange={(e) => item.onChange({...item.price, allDay: parseInt(e.target.value)})}/>
            </td>
          </tr>
        ))}
        <tr>
          <td className="bold">* 노출 여부</td>
          <td/>
          <td>
            <div className={`flex-center${!isEditing ? " disabled-check" : ""}`} style={{gap: 30}}>
              <Form.Check
                type={"checkbox"}
                id={"nightShowY"}
                label={"노출"}
                disabled={!isEditing}
                checked={nightShow}
                onChange={() => setNightShow(true)}
              />
              <Form.Check
                type={"checkbox"}
                id={"nightShowN"}
                label={"미노출"}
                disabled={!isEditing}
                checked={!nightShow}
                onChange={() => setNightShow(false)}
              />
            </div>
          </td>
          <td/>
        </tr>
        </tbody>
      </Table>

    {isEditing &&
      <div className="flex ml-auto" style={{justifyContent: 'flex-end', gap: 6}}>
        <button type="button" className="grey-btn" onClick={onCancel}>취소</button>
        <button type="button" className={isCreating ? "green-btn" : "blue-btn"} onClick={() => setConfirmOpen(true)}>{isCreating ? "등록" : "저장"}</button>
      </div>
    }
    {!isEditing && !isCreating &&
      <div className="flex ml-auto" style={{justifyContent: 'flex-end', gap: 6}}>
        <button type="button" className="red-btn" onClick={() => setDeleteConfirmOpen(true)}>삭제</button>
        <button type="button" className="blue-btn ml-auto" onClick={() => setIsEditing(true)}>수정</button>
      </div>
    }

    <ModalDialog show={confirmOpen}
                 onHide={() => {
                   setConfirmOpen(false)
                 }}
                 btnText={"예"} secondBtnText={"아니요"}
                 hasTwoButtons
                 onOk={onSave}
                 onClickSecond={() => {
                   setConfirmOpen(false)
                 }}
    >
      <p style={{textAlign: 'center'}}>
        수정한 가격을 저장하시겠습니까?
      </p>
    </ModalDialog>
    <ModalDialog show={deleteConfirmOpen}
                 onHide={() => {
                   setDeleteConfirmOpen(false)
                 }}
                 btnText={"예"} secondBtnText={"아니요"}
                 hasTwoButtons
                 onOk={onDelete}
                 onClickSecond={() => {
                   setDeleteConfirmOpen(false)
                 }}
    >
      <p style={{textAlign: 'center'}}>
        <b>{format(new Date(startDt), DT_FORMAT)} ~ {format(new Date(endDt), DT_FORMAT)}</b>
        <br/>
        가격표를 삭제하시겠습니까?
      </p>
    </ModalDialog>
  </>
  )
}