/* eslint-disable */
import React, {createContext, useState} from "react";


export const AlertContext = createContext({});

export const AlertContextProvider = ({children}) => {

  const [show, setShow] = useState(false);
  const [toastMsg, setToastMsg] = useState("");

  return (
    <AlertContext.Provider
      value={{
        show,
        setShow,
        toastMsg,
        setToastMsg
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};