import React, {useState} from "react";
import {ReactComponent as ArrowIcon} from "../../assets/icons/arrow.svg";

export function SelectInput(props) {

  const {
    selected,
    selectItems = [],
    onSelect = () => {},
    onChange = () => {},
    placeholder = "",
    defaultValue,
    className,
    ...otherProps
  } = props;

  const [open, setOpen] = useState(false);

  return (
    <div className={`select-with-input ${className ?? ""}`} {...otherProps}>
      <div className="select-btn" onClick={() => setOpen(!open)}>
        <span>{selected?.text ?? ""}</span>
        <ArrowIcon style={{transform: open ? "rotate(90deg)" : "rotate(-90deg)"}}/>
      </div>
      <div className={`select-list${open ? " select-open" : ""}`}>
        <ul>
          {selectItems.map((item, idx) => (
            <li key={"select-"+idx}
                onClick={() => {
                  onSelect(item);
                  setOpen(false);
                }}>{item.text}</li>
          ))}
        </ul>
      </div>
      <input type="text" placeholder={placeholder} defaultValue={defaultValue}
             onChange={(e) => onChange(e.target.value)}/>
    </div>
  )
}