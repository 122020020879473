import CustomDatePicker from "common/components/DatePicker";
import {Form, Table} from "react-bootstrap";
import React, {useEffect, useRef, useState, useContext, useMemo} from "react";
import {SelectInput} from "common/components/SelectInput";
import Pagination from "common/components/Pagination";
import AdminApi, {KakaoApi} from "common/api";
import {format} from "date-fns";
import {getReservationType} from "./main";
import {dateFormat, formatPhoneNumber} from "common/utils";
import ModalDialog from "common/components/Modal";
import {AlertContext} from "context/AlertContext";


/*** 예약 관리 ***/
export function BookingManagement({updateDashboard}) {

  const api = new AdminApi();
  const kakaoApi = new KakaoApi();

  const {setShow, setToastMsg} = useContext(AlertContext);
  const ls = localStorage;
  const now = new Date();
  const sp = useRef(ls.getItem("bookingSearch") ?
    {params: {
      ...JSON.parse(ls.getItem("bookingSearch")).params,
      showToday: false,
      startDate: format((new Date()).setDate(1), "yyyy-MM-dd 00:00:00"),
      endDate: format(now, "yyyy-MM-dd 23:59:59")
    }}
    :
    {params: {
      showToday: false, status: "WAITING",
      startDate: format((new Date()).setDate(1), "yyyy-MM-dd 00:00:00"),
      endDate: format(now, "yyyy-MM-dd 23:59:59")
    }});

  const columns = useMemo(() => {
    return getColumns(sp.current.params.status)
  }, [sp.current.params])
  const timeTypes = ["daytime", "nighttime", "allDay"];
  const timeTypesKo = ["주간", "야간", "종일"];
  const reservedTimeTypes = ["주간 이용 (09:00 ~ 17:00)", "야간 이용 (17:30 ~ 21:30)", "종일 이용 (09:00 ~ 21:30)"];

  const [startDt, setStartDt] = useState((new Date()).setDate(1));
  const [endDt, setEndDt] = useState(now);
  const [checkedTime, setCheckedTime] = useState(sp.current?.params.reservedTimeType ? timeTypes[sp.current?.params.reservedTimeType-1] : "all");
  const [checkedType, setCheckedType] = useState(sp.current?.params.status ?? "WAITING");
  const [data, setData] = useState(null);

  const [tableData, setTableData] = useState(null); // 현재 페이지 표 데이터
  const PAGE_SIZE = 15;
  const currentPage = useRef(1);
  const [totalCount, setTotalCount] = useState(0);

  const typeFilters = [
    {id: "WAITING", label: "예약고객"},
    {id: "RESERVED", label: "확정된 예약"},
    {id: "CANCEL_REQUEST", label: "취소 신청"},
    {id: "CANCELLED", label: "취소된 예약"}
  ]
  const timeFilters = [
    {id: "all", label: "전체"},
    {id: "daytime", label: "주간"},
    {id: "nighttime", label: "야간"},
    {id: "allDay", label: "종일"}
  ]

  const selectItems = [
    {value: "name", text: "예매자명"},
    {value: "phone", text: "전화번호"},
    {value: "bookId", text: "예매번호"},
    {value: "bedLocation", text: "평상번호"}
  ]
  const [keywordType, setKeywordType] = useState(selectItems[0]);
  const [keyword, setKeyword] = useState("");

  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);

  const [confirmCancel, setConfirmCancel] = useState(false);
  const [cancelItem, setCancelItem] = useState(null);

  const [memoEdit, setMemoEdit] = useState(0);
  const [memoText, setMemoText] = useState("");

  function getColumns(status) {
    switch (status) {
      case "WAITING": return ["No.", "예약자 이름", "전화번호", "예약신청일", "입장일", "주/야간", "인원", "평상", "평상 가격", "쿠폰 사용 유무"];
      case "RESERVED": return ["No.", "예약자 이름", "전화번호", "예약신청일", "예약번호", "입장일", "주/야간", "인원", "평상", "평상 가격", "쿠폰 사용 유무"];
      case "CANCEL_REQUEST": return ["No.", "예약자 이름", "전화번호", "예약번호", "입장일", "취소신청시간", "주/야간", "인원", "평상", "은행정보"];
      case "CANCELLED": return ["No.", "예약자 이름", "전화번호", "취소일", "입장일", "주/야간", "인원", "평상", "은행정보"];
    }
  }

  function onCheckTime(e) {
    let id = e.target.id;
    setCheckedTime(id);
  }

  function onCheckType(e) {
    let id = e.target.id;
    setCheckedType(id);
  }


  function fetchData(callbackFunc) {
    api.getBookingList(sp.current.params)
      .then((res) => {
        if (res?.infos) {
          setData(res.infos);
          setTotalCount(res.infos.length);
        } else {
          setData(null);
          setTotalCount(0);
        }
        if (callbackFunc) callbackFunc(res);
      })
  }

  function onSearch() {
    currentPage.current = 1;
    let params = {
      showToday: false,
      status: checkedType,
      [keywordType.value]: keyword!=="" ? keyword : null,
      startDate: startDt ? format(startDt, "yyyy-MM-dd 00:00:00") : null,
      endDate: endDt ? format(endDt, "yyyy-MM-dd 23:59:59") : null,
      reservedTimeType: checkedTime === "all" ? null : getReservationType(checkedTime)
    }
    ls.setItem("bookingSearch", JSON.stringify({params: params, selectedKeyType: keywordType}));
    sp.current = {params: params, selectedKeyType: keywordType};
    fetchData((data) => {
      if (data?.infos?.length === 0) alert("검색 결과를 찾을 수 없습니다.");
    });
  }

  useEffect(() => {
    fetchData();

    const updateList = setInterval(() => {
      fetchData();
    }, 60000)
    return () => {
      clearInterval(updateList);
    }
  }, [])

  useEffect(() => {
    if (data) {
      setTableData(data.slice(currentPage.current * PAGE_SIZE - PAGE_SIZE, currentPage.current * PAGE_SIZE));
    } else setTableData(null);
  }, [data])

  function onPageChange(page) {
    currentPage.current = page;
    if (data) {
      setTableData(data.slice(page * PAGE_SIZE - PAGE_SIZE, page * PAGE_SIZE));
    }
  }

  function updateStatusReserved() {
    api.updateBooking({status: "RESERVED", bookNo: updateItem.bookNo})
      .then((res) => {
        if (res?.data?.result === "ok") {
          setToastMsg("저장되었습니다");
          let resDate = format(updateItem.reservedDate, "yyyy년 MM월 dd일");
          let resPhone = formatPhoneNumber(updateItem.phone);
          let resTime = reservedTimeTypes[updateItem.reservedTimeType-1];
          let resBed = updateItem.reservedTimeType !== 2 ? JSON.parse(updateItem.bedLocation).map(b => b+"번").join(", ") : "-";
          kakaoApi.sendBookingConfirmation(resDate, resPhone, resTime, resBed);
          api.sendPushMessage({
            users: [updateItem.userNo],
            title: '예약이 확정 되었어요!',
            body: `${resDate} 평상 예약이 확정 되었어요. 그날 봐요!!`,
            service: 'pool'
          })
          fetchData();
        } else {
          setToastMsg("실패하였습니다");
        }
        setShow(true);
      }).finally(() => {
        setConfirmUpdate(false);
        setUpdateItem(null);
        updateDashboard();
      })
  }

  function updateStatusCancelled() {
    api.updateBooking({
      status: "CANCELLED", bookNo: cancelItem.bookNo,
      cancelledAt: format(new Date(), "yyyy-MM-dd hh:MM:dd")})
      .then((res) => {
        if (res?.data?.result === "ok") {
          setToastMsg("취소되었습니다");
          let resDate = format(cancelItem.reservedDate, "yyyy년 MM월 dd일");
          let resPhone = formatPhoneNumber(cancelItem.phone);
          kakaoApi.sendCancelConfirmation(resDate, resPhone);
          api.sendPushMessage({
            users: [cancelItem.userNo],
            title: '예약이 취소 되었어요!',
            body: `신청 하신 ${resDate} 예약이 정상적으로 취소 되었어요. 예약금은 매월 15일 / 말일에 일괄적으로 환불해 드려요.`,
            service: 'pool'
          })
          fetchData();
        } else {
          setToastMsg("실패하였습니다");
        }
        setShow(true);
      }).finally(() => {
        setConfirmCancel(false);
        setCancelItem(null);
        updateDashboard();
      })
  }


  function updateAdminMemo() {
    api.updateBooking({status: "CANCELLED", bookNo: memoEdit, memo: memoText && memoText!=="" ? memoText : null})
      .then((res) => {
        if (res?.data?.result === "ok") {
          setToastMsg("메모 저장되었습니다");
          fetchData();
        } else {
          setToastMsg("실패하였습니다");
        }
        setShow(true);
      })
      .catch(() => {
        setToastMsg("실패하였습니다");
        setShow(true);
      })
      .finally(() => {
        setMemoEdit(0);
        setMemoText("");
      })
  }


  function getCountName(status) {
    switch (status) {
      case "WAITING": return "예약 건 수";
      case "RESERVED": return "확정된 예약 건 수";
      case "CANCEL_REQUEST": return "취소 신청 수";
      case "CANCELLED": return "취소된 예약";
    }
  }

  useEffect(() => {
    setMemoText("");
  }, [memoEdit])

  return (
    <article style={{minWidth: 1600}}>
      <div className="search-filters" style={{marginBottom: 20}}>
        <div style={{flexDirection: 'column', gap: 6}}>
          <div style={{display: 'flex', alignItems: "center"}}>
            <CustomDatePicker placeholder="시작일" value={startDt}
                              onChange={setStartDt} maxDate={endDt ?? undefined}/>
            <span style={{margin: "0 6px"}}>~</span>
            <CustomDatePicker placeholder="종료일" value={endDt}
                              onChange={setEndDt} minDate={startDt ?? undefined}/>
          </div>
          <SelectInput placeholder="입력 후 검색하세요"
                       selected={keywordType}
                       onSelect={setKeywordType}
                       onChange={setKeyword}
                       selectItems={selectItems}
                       className={"booking-search-input"}
          />
        </div>
        <div style={{flexDirection: 'column', padding: "0 20px", gap: 6, minWidth: 680}}>
          <div className="filter-checkbox">
            <span>유형 구분</span>
            {typeFilters.map((item) => (
              <Form.Check
                key={"체크-"+item.label}
                type={"checkbox"}
                id={item.id}
                label={item.label}
                checked={checkedType === item.id}
                onChange={onCheckType}
              />
            ))}
          </div>
          <div className="filter-checkbox">
            <span>주야간 구분</span>
            <div>
              {timeFilters.map((item) => (
                <Form.Check
                  key={"체크-"+item.label}
                  type={"checkbox"}
                  id={item.id}
                  label={item.label}
                  checked={checkedTime === item.id}
                  onChange={onCheckTime}
                />
              ))}
            </div>
          </div>
        </div>
        <button className="blue-btn" type="button" onClick={onSearch}>검색</button>
        <span className="text-grey bold" style={{marginTop: 10}}>{getCountName(sp.current.params.status)}: {totalCount}</span>
      </div>

      <Table bordered className="basic-table book-mgt-table">
        <thead>
        <tr>
          {columns.map((col, colIdx) => (
            <th key={col} className="thick-border-top"
                colSpan={(sp.current.params.status==="CANCEL_REQUEST" && colIdx===9) || ((sp.current.params.status==="CANCELLED" && colIdx===8)) ? 3 : 1}
                rowSpan={(sp.current.params.status==="CANCEL_REQUEST" && colIdx!==9) || ((sp.current.params.status==="CANCELLED" && colIdx!==8)) ? 2 : 1} >{col}</th>
          ))}
        </tr>
        {["CANCEL_REQUEST", "CANCELLED"].includes(sp.current.params.status) &&
          <tr>
            <th style={{width: 140}}>받는 사람</th>
            <th style={{width: 140}}>은행</th>
            <th style={{width: 180}}>계좌번호</th>
          </tr>
        }
        </thead>
        <tbody>
        {tableData && tableData.length > 0 ? tableData.map((info, idx) => (
          <React.Fragment key={"row"+idx}>
            <tr>
              <td rowSpan={2} className="thick-border bold" style={{width: 60}}>{idx + 1 + (PAGE_SIZE * (currentPage.current - 1))}</td>
              <td>{info.name}</td>
              <td>{info.phone}</td>
              {["WAITING", "RESERVED", "DONE"].includes(info.status) && <td>{dateFormat(info.createdAt, true)}</td>}
              {(sp.current.params.status === "RESERVED" && info.status === "CANCEL_REQUEST") && <td>{dateFormat(info.createdAt, true)}</td>}
              {["CANCELLED"].includes(info.status) && <td>{dateFormat(info.cancelledAt, true)}</td>}
              {["RESERVED", "DONE", "CANCEL_REQUEST"].includes(info.status) && <td>{info.bookId}</td>}
              <td>{dateFormat(info.reservedDate)}</td>
              {sp.current.params.status !== "RESERVED" && ["CANCEL_REQUEST"].includes(info.status) && <td>{dateFormat(info.updatedAt, true)}</td>}
              <td>{timeTypesKo[info.reservedTimeType-1]}</td>
              <td>대인 {info.adult ?? 0}{info.junior ? ", 소인 " + info.junior : ""}</td>
              <td>{info.bedLocation && JSON.parse(info.bedLocation).map(b => b+"번").join(", ")}</td>
              {(["WAITING", "RESERVED", "DONE"].includes(info.status) ||
                  (sp.current.params.status === "RESERVED" && info.status === "CANCEL_REQUEST")) &&
                <>
                  <td>{info.bookingBill?.toLocaleString() ?? ""}</td>
                  <td>{info.couponDc && info.couponDc!== 0 ? info.couponDc : ""}</td>
                </>
              }
              {["CANCEL_REQUEST", "CANCELLED"].includes(sp.current.params.status) &&
                <>
                  <td>{info.cancelName}</td>
                  <td>{info.bank}</td>
                  <td>{info.account}</td>
                </>
              }
            </tr>
            <tr style={{textAlign: 'left'}}>
              <td colSpan={["WAITING", "RESERVED", "DONE"].includes(info.status) ||
              (sp.current.params.status === "RESERVED" && info.status === "CANCEL_REQUEST") ? 10 :
                sp.current.params.status === "CANCEL_REQUEST" ? 8 : 7} className="thick-border status-cell">
                <div>
                  <span>{["WAITING", "RESERVED", "DONE"].includes(info.status) ||
                  (sp.current.params.status === "RESERVED" && info.status === "CANCEL_REQUEST") ? "예약 확정" : "취소 유무"}</span>
                  {info.status === "WAITING" &&
                    <button className="green-btn" type="button" onClick={() => {
                      setUpdateItem(info);
                      setConfirmUpdate(true);
                    }}>확정하기</button>
                  }
                  {sp.current.params.status === "CANCEL_REQUEST" && info.status === "CANCEL_REQUEST" &&
                    <button className="red-btn" type="button" onClick={() => {
                      setCancelItem(info);
                      setConfirmCancel(true);
                    }}>취소하기</button>
                  }
                  {(info.status === "RESERVED" || info.status === "DONE"
                      || (sp.current.params.status === "RESERVED" && info.status === "CANCEL_REQUEST")) &&
                    <div className="flex" style={{gap: 8}}>
                      <button className="grey-btn status-btn" type="button">확정 완료했어요</button>
                      {info.status === "DONE" && <button className="dark-btn status-btn" type="button">입장 완료했어요</button>}
                      {info.status === "CANCEL_REQUEST" && <button className="dark-btn status-btn" type="button">취소 신청중</button>}
                    </div>
                  }
                  {info.status === "CANCELLED" &&
                    <button className="grey-btn status-btn" type="button">취소 완료</button>
                  }
                </div>
              </td>
              {sp.current.params.status !== "RESERVED" && ["CANCEL_REQUEST", "CANCELLED"].includes(info.status) &&
                <td colSpan={3} className="thick-border">
                  <div className="cancel-reason">
                    <span>취소 사유: </span>
                    <p>{info.cancelReason ?? ""}</p>

                    {sp.current.params.status === "CANCELLED" &&
                      <React.Fragment>
                        <div className="cancel-admin-memo">
                          <span>관리자 메모:</span>
                          {memoEdit!==info.bookNo && <button type="button" className="blue-btn"
                                   onClick={() => {
                                     setMemoEdit(info.bookNo);
                                     if (info.memo) setMemoText(info.memo);
                                   }}>메모 {info.memo ? "수정" : "추가"}</button> }
                          {memoEdit===info.bookNo &&
                            <>
                              <button type="button" className="grey-btn" onClick={() => setMemoEdit(0)}>취소</button>
                              <button type="button" className="green-btn" onClick={updateAdminMemo}>메모 저장</button>
                            </>
                          }
                        </div>
                        {memoEdit!==info.bookNo && info.memo && <p className="admin-memo">{info.memo}</p>}
                        {memoEdit===info.bookNo &&
                          <textarea id="memo" defaultValue={info.memo} rows={3} maxLength={100}
                                    placeholder="메모를 입력하세요 (100글자까지)"
                                    onChange={(e) => setMemoText(e.target.value)}/>
                        }
                      </React.Fragment>
                    }
                  </div>
                </td>
              }
            </tr>
          </React.Fragment>))
          :
          <tr>
          <td colSpan={12}>데이터가 없습니다</td>
          </tr>
        }
        </tbody>
      </Table>

      <Pagination total={data?.length ?? 0} pageSize={PAGE_SIZE}
                  page={currentPage.current} onPageChange={onPageChange}/>


      <ModalDialog show={confirmUpdate}
                   title={"확인"}
                   onHide={() => {
                     setConfirmUpdate(false);
                     setUpdateItem(null);
                   }}
                   btnText={"예"}
                   hasTwoButtons
                   secondBtnText={"아니요"}
                   onOk={updateStatusReserved}
                   onClickSecond={() => {
                     setUpdateItem(null);
                     setConfirmUpdate(false);
                   }}
      >
        {updateItem &&
          <p style={{textAlign: 'center'}}>
            {updateItem.name}의 {dateFormat(updateItem.reservedDate)} {timeTypesKo[updateItem.reservedTimeType - 1]} 예약을 확정
            하겠습니까?
          </p>
        }
      </ModalDialog>
      <ModalDialog show={confirmCancel}
                   title={"확인"}
                   onHide={() => {
                     setConfirmCancel(false);
                     setUpdateItem(null);
                   }}
                   btnText={"예"}
                   hasTwoButtons
                   secondBtnText={"아니요"}
                   onOk={updateStatusCancelled}
                   onClickSecond={() => {
                     setUpdateItem(null);
                     setConfirmCancel(false);
                   }}
      >
        {cancelItem &&
          <p style={{textAlign: 'center'}}>
            {cancelItem.name}의 {dateFormat(cancelItem.reservedDate)}의 예약 취소 신청을 승인 하겠습니까?
          </p>
        }
      </ModalDialog>
    </article>
  )
}