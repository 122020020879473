import {Form} from "react-bootstrap";
import {useEffect, useState, useContext} from "react";
import Tabs from "common/components/Tabs";
import {TodayGuests} from "./today";
import {BookingManagement} from "./booking";
import {PriceManagement} from "./price";
import ModalDialog from "common/components/Modal";
import React from "react";
import AdminApi from "common/api";
import CustomDatePicker from "common/components/DatePicker";
import {format} from "date-fns";
import {AlertContext} from "context/AlertContext";
import {dateFormat} from "common/utils";

export function getReservationType(type) {
  switch (type) {
    case "daytime": return 1;
    case "nighttime": return 2;
    case "allDay": return 3;
  }
}

export default function PoolTab() {

  const api = new AdminApi();
  const {setShow, setToastMsg} = useContext(AlertContext);
  
  const [poolMenuShow, setPoolMenuShow] = useState(null);
  const [closedCheck, setClosedCheck] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmShowOpen, setConfirmShowOpen] = useState(false);
  const [confirmClosedOpen, setConfirmClosedOpen] = useState(false);
  const [confirmClosedUncheck, setConfirmClosedUncheck] = useState(false);

  const [stats, setStats] = useState(null);
  const [priceInfo, setPriceInfo] = useState(null);
  const [updatePrice, setUpdatePrice] = useState(false);
  const [poolInfo, setPoolInfo] = useState(null);

  const today = new Date();
  const [poolExposureEnd, setPoolExposureEnd] = useState(today); // 수영장이 서비스 메뉴에서 미노출 될 날짜
  const [closedDate, setClosedDate] = useState(today); // 휴무

  function fetchPoolData() {
    api.getPoolInfo()
      .then((res) => {
        if (res?.infos) {
          setPoolInfo(res.infos);
          setPriceInfo(res.infos.price);
          if (res.infos.exposureEndDate !== null) {
            setPoolExposureEnd(res.infos.exposureEndDate);
            setPoolMenuShow(false);
          } else setPoolMenuShow(true);
          if (res.infos.closedDate !== null) {
            setClosedDate(res.infos.closedDate);
            setClosedCheck(true);
          }
        } else setPriceInfo(null);
      })
  }

  function fetchStatsData() {
    api.getBookingStats()
      .then((res) => {
        if (res?.infos) {
          setStats(res.infos)
        } else setStats(null);
      })
  }

  useEffect(() => {
    fetchStatsData();
    const updateList = setInterval(() => {
      fetchStatsData();
    }, 60000)
    return () => {
      clearInterval(updateList);
    }
  }, [])
  
  function updatePoolExposure(date, showBool) {
    api.updatePoolInfo({
      exposureEndDate: date ? format(date, "yyyy-MM-dd") : null,
      closedDate: poolInfo?.closedDate
    })
      .then((res) => {
        if (res?.result === "ok") {
          setToastMsg("수정되었습니다");
          setPoolMenuShow(showBool);
          fetchPoolData();
        } else {
          setPoolExposureEnd(poolInfo.exposureEndDate ?? today);
          setToastMsg("실패하였습니다");
        }
        setShow(true);
      })
      .finally(() => {
        setConfirmOpen(false);
        setConfirmShowOpen(false);
      })
  }

  function updatePoolClosed(date) {
    api.updatePoolInfo({
      exposureEndDate: poolInfo?.exposureEndDate,
      closedDate: date ? format(date, "yyyy-MM-dd") : null})
      .then((res) => {
        if (res?.result === "ok") {
          setToastMsg("수정되었습니다");
          setClosedCheck(!!date);
          fetchPoolData();
        } else {
          setClosedDate(poolInfo.closedDate ?? today);
          setToastMsg("실패하였습니다");
          setClosedCheck(false);
        }
        setShow(true);
      })
      .finally(() => {
        setConfirmClosedOpen(false);
        setConfirmClosedUncheck(false);
      })
  }

  useEffect(() => {
    fetchPoolData();
  }, [updatePrice])

  return (
    <div>
      <div className="current-status-box">
        <div className="pool-booking-status">
          <div>
            <h2>수영장 예약 현황</h2>
            <span>수영장 메뉴 노출여부</span>
            <Form.Check
              type={"checkbox"}
              id={`show`}
              label={`노출`}
              checked={poolMenuShow ?? false}
              onChange={(e) => {
                if (e.target.checked) setConfirmShowOpen(true);
              }}
            />
            <Form.Check
              type={"checkbox"}
              id={`hide`}
              label={`미노출`}
              checked={!!poolInfo?.exposureEndDate}
              onChange={(e) => {
                if (e.target.checked) setConfirmOpen(true);
              }}
            />
            {poolInfo?.exposureEndDate && !poolMenuShow &&
              <span className="pool-exposure-end-date">(노출 마지막날: {format(poolExposureEnd, "yyyy-MM-dd")})</span>}
          </div>

          <div className="closed-date">
            <Form.Check
              type={"checkbox"}
              id={`closedDate`}
              label={`휴무`}
              checked={closedCheck ?? false}
              onChange={(e) => {
                if (e.target.checked) {
                  setConfirmClosedOpen(true);
                } else setConfirmClosedUncheck(true);
              }}
            />
            <span>{closedCheck ? "("+dateFormat(closedDate)+")" : ""}</span>
      </div>

          <div style={{marginTop: -5}}>
            <div>
              <p className="bold">{stats ? stats.waitingCount?.toLocaleString() : 0}</p>
              <span className="text-grey">확정 대기중</span>
            </div>
            <div>
              <p className="bold">{stats ? stats.reservedCount?.toLocaleString() : 0}</p>
              <span className="text-grey">확정된 전체 예약</span>
            </div>
            <div>
              <p className="bold">{stats ? stats.cancelledCount?.toLocaleString() : 0}</p>
              <span className="text-grey">예약 취소</span>
            </div>
          </div>
        </div>
        <div className="today-booking-status">
          <h2>오늘의 예약 현황</h2>
          <div>
            <div>
              <span className="text-grey">총 예약 평상 수: {stats ? stats.todayBedCountTotal?.toLocaleString() : 0}</span>
              <p className="bold">{stats ? stats.todayBedCount?.toLocaleString() : 0}</p>
              <span className="text-grey">남은 평상</span>
            </div>
            <div>
              <span className="text-grey">총 예약 건 수: {stats ? stats.todayBookingCountTotal?.toLocaleString() : 0}</span>
              <p className="bold">{stats ? stats.todayBookingCount?.toLocaleString() : 0}</p>
              <span className="text-grey">남은 예약 건 수</span>
            </div>
            <div>
              <span className="text-grey">총 입장 인원: {stats ? stats.todayPeopleCountTotal?.toLocaleString() : 0}</span>
              <p className="bold">{stats ? stats.todayPeopleCount?.toLocaleString() : 0}</p>
              <span className="text-grey">남은 입장 인원</span>
            </div>
          </div>
        </div>
      </div>

      <Tabs
        tabNames={["오늘 입장", "예약 관리", "평상 및 입장권 가격"]}
        tabPages={[
          <TodayGuests priceInfo={priceInfo} updateDashboard={fetchStatsData}/>,
          <BookingManagement updateDashboard={fetchStatsData}/>,
          <PriceManagement
            initPrice={priceInfo}
            updatePrice={() => setUpdatePrice(!updatePrice)}
            initExp={poolInfo?.exposureEndDate}
          />
        ]}
      />

      <ModalDialog show={confirmShowOpen}
                   onHide={() => {
                     setConfirmShowOpen(false)
                   }}
                   btnText={"예"} secondBtnText={"아니요"}
                   hasTwoButtons
                   onOk={() => updatePoolExposure(null, true)}
                   onClickSecond={() => {
                     setConfirmShowOpen(false)
                   }}
      >
        <p style={{textAlign: 'center'}}>
          수영장 메뉴 미노출 설정을 초기화 하시겠습니까?
        </p>
      </ModalDialog>
      <ModalDialog show={confirmOpen}
                   onHide={() => {
                     setPoolExposureEnd(today);
                     setConfirmOpen(false)
                   }}
                   btnText={"예"} secondBtnText={"아니요"}
                   hasTwoButtons
                   onOk={() => updatePoolExposure(poolExposureEnd, false)}
                   onClickSecond={() => {
                     setPoolExposureEnd(today);
                     setConfirmOpen(false)
                   }}
      >
        <div className="flex-center" style={{marginBottom: 20}}>
          <CustomDatePicker value={poolExposureEnd} minDate={today}
                            onChange={(val) => setPoolExposureEnd(val)}/>
        </div>
        <p style={{textAlign: 'center'}}>
          해당 날짜부터 예약 불가 및 수영장 메뉴를 앱에서 미노출 시키겠습니까?<br/>
          "예" 하시면 설정하신 날짜의 24:00시부터 예약 불가 및 수영장이 메뉴에서 미노출 됩니다.
        </p>
      </ModalDialog>

      <ModalDialog show={confirmClosedUncheck}
                   onHide={() => {
                     setConfirmClosedUncheck(false)
                   }}
                   btnText={"예"} secondBtnText={"아니요"}
                   hasTwoButtons
                   onOk={() => updatePoolClosed(null)}
                   onClickSecond={() => {
                     setConfirmClosedUncheck(false)
                   }}
      >
        <p style={{textAlign: 'center'}}>
          휴무 날짜를 초기화 하시겠습니까?
        </p>
      </ModalDialog>
      <ModalDialog show={confirmClosedOpen}
                   onHide={() => {
                     setClosedDate(today);
                     setConfirmClosedOpen(false)
                   }}
                   btnText={"예"} secondBtnText={"아니요"}
                   hasTwoButtons
                   onOk={() => updatePoolClosed(closedDate)}
                   onClickSecond={() => {
                     setClosedDate(today);
                     setConfirmClosedOpen(false)
                   }}
      >
        <div className="flex-center" style={{marginBottom: 20}}>
          <CustomDatePicker value={closedDate} minDate={today}
                            onChange={(val) => setClosedDate(val)}/>
        </div>
        <p style={{textAlign: 'center'}}>
          해당 날짜 하루동안 휴무를 하시겠습니까?<br/>
          "예" 하시면 예약 시 해당 날짜는 선택할 수 없게 됩니다.
        </p>
      </ModalDialog>
    </div>
  )
}

