import React, {useState} from "react";

export default function Tabs(props) {

  const {tabNames = [], tabPages = [], className = "",...otherProps} = props;
  const [selected, setSelected] = useState(0);

  return (
    <div className={`sub-tabs-component ${className}`} {...otherProps}>
      <div className="sub-tabs">
        {tabNames.map((tab, idx) => (
          <div key={"tab-"+idx} onClick={() => setSelected(idx)} className={selected === idx ? "selected-tab" : ""}>
            <span>{tab}</span>
          </div>
        ))}
      </div>
      {tabPages[selected] &&
        <article className="sub-tab-wrapper">
          {tabPages[selected]}
        </article>
      }
    </div>
  )
}