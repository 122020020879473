import Tabs from "common/components/Tabs";
import {useState} from "react";
import {GameRoomPage, GameRoomList} from "./room";
import {AddressList} from "./address";

export default function GameTab() {

  const [openRoomPage, setOpenRoomPage] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editRoom, setEditRoom] = useState(null);

  function onEdit(room) {
    setEditRoom(room);
    setIsEdit(true);
    setOpenRoomPage(true);
  }

  function onClosePage() {
    setOpenRoomPage(false);
    if (isEdit) {
      setIsEdit(false);
      setEditRoom(null);
    }
  }

  return (
    <div>
      <div className="tab-depth-line">
        <h1 onClick={() => setOpenRoomPage(false)}>토리를 구해줘</h1>
        {openRoomPage && <h1>> {!isEdit ? "방 개설하기" : "방 수정하기"}</h1>}
      </div>

      {openRoomPage ?
        <GameRoomPage
          onCancel={onClosePage}
          isEdit={isEdit}
          editRoom={editRoom}
        />
        :
        <Tabs
          tabNames={["게임 방 리스트", "배송지 정보"]}
          tabPages={[
            <GameRoomList openRoomCreate={setOpenRoomPage}
                          onEdit={onEdit}/>,
            <AddressList/>
          ]}
        />
      }
    </div>
  )
}