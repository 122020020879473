import React, {useState, useRef, useEffect} from "react";
import CustomDatePicker from "common/components/DatePicker";
import {Form, Table} from "react-bootstrap";
import Pagination from "common/components/Pagination";
import AdminApi, {KakaoApi} from "common/api";
import {dateFormat, formatPhoneNumber} from "common/utils";
import {useContext} from "react";
import {AlertContext} from "context/AlertContext";
import ModalDialog from "common/components/Modal";
import {format} from "date-fns";


/*** 배송지 정보 ***/
export function AddressList() {

  const api = new AdminApi();
  const kakaoApi = new KakaoApi();
  const {setShow, setToastMsg} = useContext(AlertContext);

  const [deliveryData, setDeliveryData] = useState(null);
  const columns = ["No.", "배송지 입력 날짜", "이름", "닉네임", "연락처", "우승 상품명", "이메일", "주소", "신규", "배송 완료유무"];

  const ls = localStorage;
  // search parameters
  const sp = useRef(ls.getItem("deliverySearch") ?
    {
      ...JSON.parse(ls.getItem("deliverySearch")),
      startDate: format((new Date()).setDate(1), "yyyy-MM-dd 00:00:00"),
      endDate: format(new Date(), "yyyy-MM-dd 23:59:59")
    }
    :
    {startDate: format((new Date()).setDate(1), "yyyy-MM-dd 00:00:00"),
      endDate: format(new Date(), "yyyy-MM-dd 23:59:59")});
  const [startDt, setStartDt] = useState((new Date()).setDate(1));
  const [endDt, setEndDt] = useState(new Date());

  const [checkAll, setCheckAll] = useState(sp.current?.deliveryStatus?.includes("all") ?? true); // 전체 체크
  const [checkNew, setCheckNew] = useState(sp.current?.deliveryStatus?.includes("new") || checkAll);
  const [checkCompleted, setCheckCompleted] = useState(sp.current?.deliveryStatus?.includes("completed") || checkAll);

  const [updateItem, setUpdateItem] = useState(null);
  const [updateConfirm, setUpdateConfirm] = useState(false);

  const [tableData, setTableData] = useState(null); // 현재 페이지 표 데이터
  const PAGE_SIZE = 15;
  const currentPage = useRef(1);

  function onCheck(e) {
    let value = e.target.checked;
    let id = e.target.id;
    switch (id) {
      case "new": setCheckNew(value); break;
      case "completed": setCheckCompleted(value); break;
      default: return null;
    }
    if (!value && checkAll) setCheckAll(false);
  }

  function onCheckAll(e) {
    let value = e.target.checked;
    setCheckAll(value);
    setCheckNew(value);
    setCheckCompleted(value);
  }

  function fetchData(callbackFunc) {
    api.getDeliveryInfoList(sp.current).then((res) => {
      if (res?.result === "ok") {
        setDeliveryData(res.infos);
      } else setDeliveryData(null);
      if (callbackFunc) callbackFunc(res);
    })
  }

  function onSearch() {
    currentPage.current = 1;
    let statusFilters = [];
    if (checkAll) {
      statusFilters = ["all"];
    } else {
      if (checkNew) statusFilters.push("new");
      if (checkCompleted) statusFilters.push("completed");
    }
    let params = {
      startDate: startDt ? format(startDt, "yyyy-MM-dd 00:00:00") : null,
      endDate: endDt ? format(endDt, "yyyy-MM-dd 23:59:59") : null,
      deliveryStatus: statusFilters.length > 0 ? statusFilters.toString() : null,
    }
    ls.setItem("deliverySearch", JSON.stringify(params));
    sp.current = params;
    if (statusFilters.length === 0) {
      setDeliveryData(null);
      return;
    }
    fetchData((data) => {
      if (data?.infos?.length === 0) alert("검색 결과를 찾을 수 없습니다.");
    });
  }

  function updateStatus() {
    api.updateDeliveryStatus(updateItem.deliveryId).then((res) => {
      if (res?.data?.result === "ok") {
        let resPhone = formatPhoneNumber(updateItem.personPhone);
        kakaoApi.sendDeliveryConfirmation(updateItem.personName, updateItem.nickname, resPhone);
        api.sendPushMessage({
          users: [updateItem.userNo],
          title: '토리를 구해줘 우승 상품 배송!',
          body: `${updateItem.personName}(${updateItem.nickname})님의 토리를 구해줘 우승 상품 배송이 시작 되었어요.`,
          service: 'tori'
        })
        setToastMsg("수정되었습니다");
        fetchData();
      } else {
        setToastMsg("실패하였습니다");
      }
      setShow(true);
    }).finally(() => {
      setUpdateItem(null);
      setUpdateConfirm(false);
    })
  }

  useEffect(() => {
    if (checkNew && checkCompleted && !checkAll) {
      setCheckAll(true);
    }
  }, [checkNew, checkCompleted])

  useEffect(() => {
    fetchData();

    const updateList = setInterval(() => {
      fetchData();
    }, 60000)

    return () => {
      clearInterval(updateList);
    }
  }, [])

  useEffect(() => {
    if (deliveryData) {
      setTableData(deliveryData.slice(currentPage.current * PAGE_SIZE - PAGE_SIZE, currentPage.current * PAGE_SIZE));
    } else setTableData(null);
  }, [deliveryData])

  function onPageChange(page) {
    currentPage.current = page;
    if (deliveryData) {
      setTableData(deliveryData.slice(page * PAGE_SIZE - PAGE_SIZE, page * PAGE_SIZE));
    }
  }

  return (
    <div>
      <div className="search-filters">
        <div style={{alignItems: "center"}}>
          <CustomDatePicker placeholder="시작일" value={startDt}
                            onChange={setStartDt} maxDate={endDt ?? undefined}/>
          <span style={{margin: "0 6px"}}>~</span>
          <CustomDatePicker placeholder="종료일" value={endDt}
                            onChange={setEndDt} minDate={startDt ?? undefined}/>
        </div>
        <div style={{flexDirection: 'column', padding: "0 20px"}}>
          <div className="filter-checkbox">
            <span>타입</span>
            <div>
              <Form.Check
                type={"checkbox"}
                id={`all`}
                label={`전체`}
                checked={checkAll}
                onChange={onCheckAll}
              />
              <Form.Check
                type={"checkbox"}
                id={`new`}
                label={`신규`}
                checked={checkNew}
                onChange={onCheck}
              />
              <Form.Check
                type={"checkbox"}
                id={`completed`}
                label={`완료`}
                checked={checkCompleted}
                onChange={onCheck}
              />
            </div>
          </div>
        </div>
        <button type="button" className="blue-btn" onClick={onSearch}>검색</button>
      </div>

      <Table bordered responsive="md" className="basic-table address-table" style={{marginTop: 30}}>
        <thead>
        <tr>
          {columns.map((col) => (
            <th key={col}>{col}</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {tableData ?
          tableData.map((info, idx) => (
            <tr key={"row"+idx}>
              <td>{idx + 1 + (PAGE_SIZE * (currentPage.current - 1))}</td>
              <td>{dateFormat(info.createdAt)}</td>
              <td>{info.personName}</td>
              <td>{info.nickname}</td>
              <td>{info.personPhone}</td>
              <td>{info.winnerGoods}</td>
              <td>{info.email}</td>
              <td>{info.address}</td>
              <td>{info.deliveryStatus === "WAITING" ? "신규" : ""}</td>
              <td className="button-cell">
                <button className={`blue-btn${info.deliveryStatus === "SENT" ? " status-btn" : ""}`}
                        disabled={info.deliveryStatus === "SENT"}
                        type="button"
                        onClick={() => {
                          setUpdateItem(info);
                          setUpdateConfirm(true);
                        }}>완료</button>
              </td>
            </tr>
          ))
          :
          <tr>
            <td colSpan={10}>데이터가 없습니다</td>
          </tr>
        }
        </tbody>
      </Table>

      <Pagination total={deliveryData?.length ?? 0} pageSize={PAGE_SIZE}
                  page={currentPage.current} onPageChange={onPageChange}/>

      <ModalDialog show={updateConfirm}
                   title={"확인"}
                   onHide={() => {
                     setUpdateConfirm(false);
                     setUpdateItem(null);
                   }}
                   btnText={"예"}
                   hasTwoButtons
                   secondBtnText={"아니요"}
                   onOk={updateStatus}
                   onClickSecond={() => {
                     setUpdateItem(null);
                     setUpdateConfirm(false);
                   }}
      >
        <p style={{textAlign: 'center'}}>
          배송 완료가 확인되었나요?
        </p>
      </ModalDialog>
    </div>
  )
}