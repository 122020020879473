import {useEffect, useState} from "react";
import {Pagination as BootstrapPagination} from "react-bootstrap";

export default function Pagination(props) {
  const {total = 0, pageSize = 10, page = 1, onPageChange = () => {}} = props;

  const totalPages = Math.ceil(total / pageSize);
  const [currentPage, setCurrentPage] = useState(page);

  function getPages() {
    let pages = [];
    if (totalPages > 0 && totalPages < 7) {
      pages = Array.from(Array(totalPages), (e, i) => i + 1)
    } else if (totalPages !== 0) {
      if (currentPage < 4 || currentPage >= totalPages - 2) {
        for (let i = 1; i <= 3; i++) {
          pages.push(i);
        }
        pages.push("...");
        for (let i = totalPages-2; i <= totalPages; i++) {
          pages.push(i);
        }
      } else if (currentPage >= 4 && currentPage <= totalPages-3) {
        pages.push(1);
        pages.push("...");
        for (let i = currentPage-1; i <= currentPage+1; i++) {
          pages.push(i);
        }
        pages.push("...");
        pages.push(totalPages);
      }
    }
    return pages;
  }

  function onPageNext() {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
      onPageChange(currentPage + 1);
    }
  }

  function onPagePrev() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      onPageChange(currentPage - 1);
    }
  }

  let pages = getPages();

  useEffect(() => {
    if (page !== currentPage) setCurrentPage(page);
  }, [page])

  return (
    <BootstrapPagination>
      <BootstrapPagination.First onClick={() => {
        setCurrentPage(1);
        onPageChange(1);
      }}/>
      <BootstrapPagination.Prev onClick={onPagePrev} />
      {pages &&
        pages.map((pg, idx) => {
          if (typeof pg === "number") {
            return <BootstrapPagination.Item key={"page"+pg} active={currentPage === pg}
                                    onClick={() => {
                                      setCurrentPage(pg);
                                      onPageChange(pg);
                                    }}>{pg}</BootstrapPagination.Item>
          } else if (pg === "...") {
            return<BootstrapPagination.Ellipsis key={"page"+pg+idx} />
          }
        })
      }
      <BootstrapPagination.Next onClick={onPageNext} />
      <BootstrapPagination.Last onClick={() => {
        setCurrentPage(pages[pages.length - 1]);
        onPageChange(pages[pages.length - 1]);
      }} />
    </BootstrapPagination>
  )
}