import React from 'react';
import { Table } from 'antd';

const columns = [
    {
        title: (
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            날짜
          </div>
        ),
        dataIndex: 'date',
        key: 'date',
        align: 'center',
      },
      {
        title: (
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            전체 회원수
          </div>
        ),
        dataIndex: 'totalMembers',
        key: 'totalMembers',
        align: 'center',
      },
      {
        title: (
            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            신규 가입 <br /> <span style={{ fontWeight: 'normal' }}>(친구소환코드)</span>
          </div>
        ),
        dataIndex: 'newMembers',
        key: 'newMembers',
        align: 'center',
      },
      {
        title: (
            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            MAU <br /> <span style={{ fontWeight: 'normal' }}>(중복유저 제외)</span>
          </div>
        ),
        dataIndex: 'mau',
        key: 'mau',
        align: 'center',
      },
      {
        title: (
            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            DAU <br /> <span style={{ fontWeight: 'normal' }}>(중복유저 제외)</span>
          </div>
        ),
        dataIndex: 'dau',
        key: 'dau',
        align: 'center',
      },
      {
        title: (
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            회원 탈퇴
          </div>
        ),
        dataIndex: 'membersWithdrawal',
        key: 'membersWithdrawal',
        align: 'center',
      },
];

const data = [
  {
    key: '1',
    date: '2024-05-01',
    totalMembers: 1000,
    newMembers: 100,
    mau: 800,
    dau: 600,
    membersWithdrawal: 50,
  },
];

const TableComponent = ({isMobile}) => {
  return <Table 
    columns={columns} 
    dataSource={data}
    scroll={{ x: isMobile?700:undefined }}
    pagination={false} 
  />;
};

export default TableComponent;