import {useEffect, useRef, useState} from "react";
import CustomDatePicker from "common/components/DatePicker";
import {Form, Table} from "react-bootstrap";
import Pagination from "common/components/Pagination";
import {differenceInMinutes, isBefore, isAfter, format} from "date-fns";
import {dateFormat} from "common/utils";
import AdminApi from "common/api";
import ModalDialog from "common/components/Modal";
import React from "react";
import {useContext} from "react";
import {AlertContext} from "context/AlertContext";
import ImageInput from "common/components/ImageInput";

/*** 게임 방 리스트 ***/
export function GameRoomList({openRoomCreate, onEdit}) {

  const api = new AdminApi();
  const ls = localStorage;
  const {setShow, setToastMsg} = useContext(AlertContext);
  const columns = ["No.", "게임 타입", "우승 상품", "방 개설일", "방 노출일", "참가비", "우승자", "토리 보유시간(초)", "참가인원", "게임 시간", "관리"];

  // search parameters
  const sp = useRef(ls.getItem("gameSearch") ?
    {
      ...JSON.parse(ls.getItem("gameSearch")),
      startDate: format((new Date()).setDate(1), "yyyy-MM-dd 00:00:00"),
      endDate: format(new Date(), "yyyy-MM-dd 23:59:59")
    }
    :
    {
      playerCount: 50,
      startDate: format((new Date()).setDate(1), "yyyy-MM-dd 00:00:00"),
      endDate: format(new Date(), "yyyy-MM-dd 23:59:59")
    });

  const [roomData, setRoomData] = useState(null);

  const [startDt, setStartDt] = useState((new Date()).setDate(1));
  const [endDt, setEndDt] = useState(new Date());
  const [checkAll, setCheckAll] = useState(sp.current?.gameStatus?.includes("all") ?? true); // 전체 체크
  const [checkActive, setCheckActive] = useState(sp.current?.gameStatus?.includes("active") || checkAll);
  const [checkWaiting, setCheckWaiting] = useState(sp.current?.gameStatus?.includes("waiting") || checkAll);
  const [checkCompleted, setCheckCompleted] = useState(sp.current?.gameStatus?.includes("completed") || checkAll);
  const [checkType, setCheckType] = useState(sp.current?.playerCount ? sp.current?.playerCount === 50 : true); // 50명

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteRoomId, setDeleteRoomId] = useState(null);

  const [tableData, setTableData] = useState(null); // 현재 페이지 표 데이터
  const PAGE_SIZE = 15;
  const currentPage = useRef(1);

  function onCheck(e) {
    let value = e.target.checked;
    let id = e.target.id;
    switch (id) {
      case "active": setCheckActive(value); break;
      case "waiting": setCheckWaiting(value); break;
      case "completed": setCheckCompleted(value); break;
      default: return null;
    }
    if (!value && checkAll) setCheckAll(false);
  }

  function onCheckAll(e) {
    let value = e.target.checked;
    setCheckAll(value);
    setCheckActive(value);
    setCheckWaiting(value);
    setCheckCompleted(value);
  }

  useEffect(() => {
    if (checkActive && checkWaiting && checkCompleted && !checkAll) {
      setCheckAll(true);
    }
  }, [checkActive, checkWaiting, checkCompleted])


  function fetchData(callbackFunc) {
    api.getGameRoomList(sp.current).then((data) => {
      if (data?.infos) {
        setRoomData(data.infos);
      } else setRoomData(null);
      if (callbackFunc) callbackFunc(data);
    }).catch(() => setRoomData(null));
  }

  useEffect(() => {
    fetchData();

    const updateList = setInterval(() => {
      fetchData();
    }, 60000 * 5)

    return () => {
      clearInterval(updateList);
    }
  },[])

  function onSearch() {
    currentPage.current = 1;
    let statusFilters = [];
    if (checkAll) {
      statusFilters = ["all"];
    } else {
      if (checkActive) statusFilters.push("active");
      if (checkWaiting) statusFilters.push("waiting");
      if (checkCompleted) statusFilters.push("completed");
    }
    let params = {
      startDate: startDt ? format(startDt, "yyyy-MM-dd 00:00:00") : null,
      endDate: endDt ? format(endDt, "yyyy-MM-dd 23:59:59") : null,
      playerCount: checkType ? 50 : null,
      gameStatus: statusFilters.length > 0 ? statusFilters.toString() : null,
    }
    ls.setItem("gameSearch", JSON.stringify(params));
    sp.current = params;
    if (statusFilters.length === 0) {
      setRoomData(null);
      return;
    }
    fetchData((data) => {
      if (data?.infos?.length === 0) alert("검색 결과를 찾을 수 없습니다.");
    });
  }

  // pagination
  useEffect(() => {
    if (roomData) {
      setTableData(roomData.slice(currentPage.current * PAGE_SIZE - PAGE_SIZE, currentPage.current * PAGE_SIZE));
    } else setTableData(null);
  }, [roomData])

  function onPageChange(page) {
    currentPage.current = page;
    if (roomData) {
      setTableData(roomData.slice(page * PAGE_SIZE - PAGE_SIZE, page * PAGE_SIZE));
    }
  }


  // 게임 상태 확인
  function getGameStatus(room) {
    let now = new Date();
    let startDate = room.beginRunningTime;
    let oneMinBeforeStart = format(new Date(startDate).setMinutes(new Date(startDate).getMinutes() - 1), "yyyy-MM-dd HH:mm:ss");
    let endDate = room.endRunningTime;

    if (isBefore(now, oneMinBeforeStart)) {
      return "beforeGame"; // 노출되어 있으나 게임 플레이 1분 전
    } else if (isAfter(now, startDate) && isBefore(now, endDate)) {
      return "inGame";  // 플레이 중
    } else if (isAfter(now, endDate)) {
      return "completed"; // 종료됨
    }
  }

  function deleteGameRoom() {
    api.deleteGameRoom(deleteRoomId).then((res) => {
      if (res?.data?.result === "ok") {
        setToastMsg("삭제되었습니다");
      } else {
        setToastMsg("실패하였습니다");
      }
      setShow(true);
      fetchData();
    }).finally(() => {
      setDeleteRoomId(null);
      setConfirmDelete(false);
    })
  }

  return (
    <div>
      <div className="search-filters">
        <div style={{alignItems: "center"}}>
          <CustomDatePicker placeholder="시작일" value={startDt}
                            onChange={setStartDt} maxDate={endDt ?? undefined}/>
          <span style={{margin: "0 6px"}}>~</span>
          <CustomDatePicker placeholder="종료일" value={endDt}
                            onChange={setEndDt} minDate={startDt ?? undefined}/>
        </div>
        <div style={{flexDirection: 'column', padding: "0 20px", gap: 6}}>
          <div className="filter-checkbox">
            <span>타입</span>
            <Form.Check
              type={"checkbox"} id={`playerCount`}
              label={`50명`} checked={checkType}
              onChange={(e) => setCheckType(e.target.checked)}
            />
          </div>
          <div className="filter-checkbox">
            <span>종료유무</span>
            <div>
              <Form.Check
                type={"checkbox"}
                id={`all`}
                label={`전체`}
                checked={checkAll}
                onChange={onCheckAll}
              />
              <Form.Check
                type={"checkbox"}
                id={`active`}
                label={`진행중`}
                checked={checkActive}
                onChange={onCheck}
              />
              <Form.Check
                type={"checkbox"}
                id={`waiting`}
                label={`준비`}
                checked={checkWaiting}
                onChange={onCheck}
              />
              <Form.Check
                type={"checkbox"}
                id={`completed`}
                label={`종료`}
                checked={checkCompleted}
                onChange={onCheck}
              />
            </div>
          </div>
        </div>
        <button className="blue-btn" type="button" onClick={onSearch}>검색</button>
      </div>

      <button className="blue-btn" style={{margin: "30px 0 20px auto"}}
              onClick={() => openRoomCreate(true)}>방 개설하기</button>

      <Table bordered responsive="md" className="basic-table">
        <thead>
        <tr>
          {columns.map((col) => (
            <th key={col} colSpan={col === "관리" ? 2 : 1}>{col}</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {tableData && tableData.length > 0 ?
          tableData.map((room, idx) => (
            <tr key={"row"+idx}>
              <td>{idx + 1 + (PAGE_SIZE * (currentPage.current - 1))}</td>
              <td>{room.playerCount}명</td>
              <td>
                {room.goodsInfo ? JSON.parse(room.goodsInfo).GOODS_NAME : ""}
              </td>
              <td>{dateFormat(room.createdAt)}</td>
              <td>{dateFormat(room.exposureDay)}</td>
              <td>{room.entryFee?.toLocaleString()}P</td>
              <td>{room.winner ?? ""}</td>
              <td style={{width: 130}}>{room.winner ? room.hostTime.toLocaleString() : ""}</td>
              <td>{(getGameStatus(room) === "completed" && room.playedUserCount) ? room.playedUserCount?.toLocaleString() : ""}</td>
              <td>{dateFormat(room.beginRunningTime, true)} ~ {dateFormat(room.endRunningTime, true, "HH:mm")}</td>
              <td className="button-cell">
                {getGameStatus(room) === "beforeGame" &&
                  <button type="button" className="blue-btn" onClick={() => onEdit(room)}>수정</button>}
                {getGameStatus(room) === "inGame" && <button type="button" className="green-btn status-btn">진행중</button>}
                {getGameStatus(room) === "completed" && <button type="button" className="dark-btn status-btn">종료</button>}
              </td>
              <td className="button-cell">
                {getGameStatus(room) === "beforeGame" &&
                  <button type="button" className="dark-btn"
                          onClick={() => {
                            setConfirmDelete(true);
                            setDeleteRoomId(room.roomId);
                          }}>삭제</button>}
              </td>
            </tr>
          ))
          :
          <tr>
            <td colSpan={11}>데이터가 없습니다</td>
          </tr>
        }
        </tbody>
      </Table>

      <Pagination total={roomData?.length ?? 0} pageSize={PAGE_SIZE}
                  page={currentPage.current} onPageChange={onPageChange}/>

      <ModalDialog show={confirmDelete}
                   title={"확인"}
                   onHide={() => {
                     setConfirmDelete(false);
                     setDeleteRoomId(null);
                   }}
                   btnText={"예"}
                   hasTwoButtons
                   secondBtnText={"아니요"}
                   onOk={deleteGameRoom}
                   onClickSecond={() => {
                     setDeleteRoomId(null);
                     setConfirmDelete(false);
                   }}
      >
        <p style={{textAlign: 'center'}}>
          게임 방 삭제하시겠습니까?
        </p>
      </ModalDialog>
    </div>
  )
}

export function GameRoomPage({onCancel, isEdit = false, editRoom}) {

  const api = new AdminApi();
  const {setShow, setToastMsg} = useContext(AlertContext);

  let editGoodsInfo = editRoom ? JSON.parse(editRoom.goodsInfo) : null;

  const [playerCount, setPlayerCount] = useState(isEdit ? editRoom.playerCount : "50");
  const [showDateTime, setShowDateTime] = useState(isEdit ? new Date(editRoom.exposureDay) : new Date());
  const [playStartTime, setPlayStartTime] = useState(isEdit ? new Date(editRoom.beginRunningTime) : null);
  const [playEndTime, setPlayEndTime] = useState(isEdit ? new Date(editRoom.endRunningTime) : null);
  const [entryFee, setEntryFee] = useState(isEdit ? editRoom.entryFee : "0");
  const [goodsId, setGoodsId] = useState(isEdit && editGoodsInfo?.GOODS_ID ? editGoodsInfo.GOODS_ID : ""); // 상품코드
  const [goodsName, setGoodsName] = useState(isEdit && editGoodsInfo ? editGoodsInfo.GOODS_NAME : "");
  const [selectedFile, setSelectedFile] = useState(null);

  const [checkType, setCheckType] = useState(isEdit && editRoom.type === "E" ? "external" : "internal");

  function onChangePlayDatetime(value, isStart = true) {
    if (isStart) {
      setPlayStartTime(value);
      let endDt = value ? new Date(value).setMinutes(new Date(value).getMinutes() + 3) : null;
      if (endDt) setPlayEndTime(new Date(endDt));
    } else {
      setPlayEndTime(value);
      let startDt = value ? new Date(value).setMinutes(new Date(value).getMinutes() - 3) : null;
      if (startDt && !playStartTime) setPlayStartTime(new Date(startDt));
    }
  }

  function addGameRoom() {
    if ([playerCount, showDateTime, playStartTime, playEndTime]
      .filter((el) => el === null || el === "" || el === undefined || (typeof el === "number" && isNaN(el))).length > 0) {
      alert("필수 항목 모두 입력해주세요");
      return;
    }
    if ((checkType === "external" && (selectedFile === null || goodsName === "")) ||
      (checkType === "internal" && goodsId === "")) {
      alert("필수 항목 모두 입력해주세요");
      return;
    }
    let params = {
      playerCount: parseInt(playerCount) ?? 50,
      exposureDay: format(showDateTime, "yyyy-MM-dd HH:mm:ss"),
      playtime: differenceInMinutes(playEndTime, playStartTime),
      entryFee: parseInt(entryFee) ?? 0,
      beginRunningTime: format(playStartTime, "yyyy-MM-dd HH:mm:ss"),
      endRunningTime: format(playEndTime, "yyyy-MM-dd HH:mm:ss"),
      goodsType: checkType,
      goodsId: goodsId,
      goodsName: goodsName
    }
    api.createGameRoom(params, selectedFile).then((res) => {
      if (res?.data?.result === "ok") {
        setToastMsg("저장되었습니다");
      } else {
        setToastMsg("실패하였습니다");
      }
      setShow(true);
      onCancel();
    })
  }

  function updateGameRoom() {
    if ([playerCount, showDateTime, playStartTime, playEndTime]
      .filter((el) => el === null || el === "" || el === undefined || (typeof el === "number" && isNaN(el))).length > 0) {
      alert("필수 항목 모두 입력해주세요");
      return;
    }
    if ((checkType === "external" && (selectedFile === null || goodsName === "")) ||
      (checkType === "internal" && goodsId === "")) {
      alert("필수 항목 모두 입력해주세요");
      return;
    }
    let params = {
      roomId: editRoom.roomId,
      playerCount: parseInt(playerCount) ?? 50,
      goodsType: checkType,
      exposureDay: format(showDateTime, "yyyy-MM-dd HH:mm:ss"),
      playtime: differenceInMinutes(playEndTime, playStartTime),
      entryFee: parseInt(entryFee) ?? 0,
      beginRunningTime: format(playStartTime, "yyyy-MM-dd HH:mm:ss"),
      endRunningTime: format(playEndTime, "yyyy-MM-dd HH:mm:ss"),
      currentGoodsId: editGoodsInfo?.GOODS_ID ?? null,
      newGoodsId: goodsId,
      goodsName: goodsName,
      currentGoodsImage: editGoodsInfo?.IMG_URL && editGoodsInfo?.IMG_URL.includes("goods_images") ? editGoodsInfo.IMG_URL : null
    }
    api.updateGameRoom(params, selectedFile).then((res) => {
      if (res?.data?.result === "ok") {
        setToastMsg("수정되었습니다");
      } else {
        setToastMsg("실패하였습니다");
      }
      setShow(true);
      onCancel();
    })
  }

  return (
    <div>
      <div className="form-container">
        <div>
          <span>* 게임 타입</span>
        </div>
        <div className="type-input">
          <input type="number" value={playerCount}
                 onChange={(e) => setPlayerCount(e.target.value)}/>
          <span style={{marginRight: 40}}>명</span>
          <Form.Check
            type={"checkbox"}
            id={`internal`}
            label={`내부 상품`}
            checked={checkType === "internal"}
            onChange={(e) => setCheckType(e.target.id)}
          />
          <Form.Check
            type={"checkbox"}
            id={`external`}
            label={`외부 상품`}
            checked={checkType === "external"}
            onChange={(e) => setCheckType(e.target.id)}
          />
        </div>
        {checkType === "internal" &&
          <React.Fragment>
            <div>
              <span>* 상품코드</span>
            </div>
            <div className="type-input">
              <input type="text" value={goodsId} onChange={(e) => setGoodsId(e.target.value)}/>
            </div>
          </React.Fragment>
        }
        {checkType === "external" &&
          <React.Fragment>
            <div>
              <span>* 상품명</span>
            </div>
            <div className="type-input">
              <input type="text" value={goodsName} onChange={(e) => setGoodsName(e.target.value)}/>
            </div>
            <div>
              <span>* 이미지</span>
            </div>
            <div className="type-input">
              <ImageInput defaultImage={editGoodsInfo?.IMG_URL ?? null} id={"goods-img"} onChange={setSelectedFile}/>
            </div>
          </React.Fragment>
        }
        <div>
          <span>* 방 노출기간</span>
        </div>
        <div>
          <CustomDatePicker value={showDateTime} onChange={setShowDateTime} showTime/>
        </div>
        <div>
          <span>* 게임 시작 시간</span>
        </div>
        <div>
          <CustomDatePicker value={playStartTime} onChange={(val) => onChangePlayDatetime(val)} showTime/>
          <span style={{margin: '0 6px'}}>~</span>
          <CustomDatePicker value={playEndTime} onChange={(val) => onChangePlayDatetime(val, false)} showTime/>
        </div>
        <div>
          <span>* 플레이 시간</span>
        </div>
        <div>
          <p>{(playStartTime && playEndTime) ? differenceInMinutes(playEndTime, playStartTime)+"분" : "0분"}</p>
        </div>
        <div>
          <span>* 참가비</span>
        </div>
        <div className="type-input">
          <input type="number" value={entryFee} step={1000} min={0}
                 onChange={(e) => setEntryFee(e.target.value)}/>
          <span>포인트</span>
        </div>
      </div>

      <div className="flex">
        <button className="grey-btn ml-auto" onClick={onCancel}>뒤로가기</button>
        <button className="blue-btn" style={{marginLeft: 8}} onClick={isEdit ? updateGameRoom : addGameRoom}>
          {isEdit ? "저장하기" : "개설하기"}
        </button>
      </div>
    </div>
  )
}